@import '../../styles/variables';

.inline-editable{
	max-width: 15em;
	.text{
		padding-right: 0.5em;
	}
	.icon{
		width: 1em;
		height: 1em;
	}
	.icon-pencil{
		cursor: pointer;
		path{
			fill: #ccc;
			&:hover{
				fill: $blue;
			}
		}
	}
	.btn-cancel{
		background: #eee;
	}
}
