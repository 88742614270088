@import './variables';

.status{
    display: inline-block;
    color: $medium-gray;
    font-size: 0.8em;
    font-weight: bold;
    letter-spacing: 0.1em;
    &.submitted{  color: $submitted;  }
    &.pending{    color: $pending;  }
    &.po-issued{   color: $poissued;   }
    &.cancelled{  color: $cancelled;  }
    &.shipped{    color: $shipped;  }
    &.closed{     color: $closed;     }
    @media print{
      &.submitted, &.pending, &.po-issued, &.cancelled, &.shipped, &.closed{
        color: inherit;
        border: 2px solid #eee;
        border-radius: 0.2em;
        padding: 0.25em 0.5em;
      }
      &.submitted{  border-color: $submitted;  }
      &.pending{    border-color: $pending;  }
      &.poissued{   border-color: $poissued;   }
      &.cancelled{  border-color: $cancelled;  }
      &.shipped{    border-color: $shipped;  }
      &.closed{     border-color: $closed;     }
    }
  }
  
  .bg-status-submitted{  background-color: $submitted;  }
  .bg-status-pending{    background-color: $pending;  }
  .bg-status-po-issued{   background-color: $poissued;   }
  .bg-status-cancelled{  background-color: $cancelled;  }
  .bg-status-shipped{    background-color: $shipped;  }
  .bg-status-closed{     cbackground-olor: $closed;     }
  
  .status-outline{
    display: inline-block;
    border: 2px solid $medium-gray;
    border-radius: $base-border-radius;
    color: #000;
    font-size: 0.8em;
    &.submitted{  border-color: $submitted;   }
    &.pending{    border-color: $pending;   }
    &.poissued{   border-color: $poissued;  }
    &.cancelled{  border-color: $cancelled;   }
    &.shipped{    border-color: $shipped;   }
    &.closed{     border-color: $closed;    }
  }
  