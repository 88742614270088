@use "sass:math";

@import "./variables";

@mixin base_typography(){
  color: $base-font-color;
  font-family: $base-font-family;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  letter-spacing: $base-font-letter-spacing;
}

.fancy{  font-family: $fancy-font-family; }

strong{
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  font-kerning: normal;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: $pad-y 0 math.div($pad-y, 3);
}

h1{ 
  @include rem-fallback(font-size, $h1);
  letter-spacing: 0;
}
h2{ 
  @include rem-fallback(font-size, $h2);
  letter-spacing: 0;
}
h3{ 
  @include rem-fallback(font-size, $h3); 
  font-weight: 300;
  text-transform: uppercase;
}
h4{ @include rem-fallback(font-size, $h4); }
h5{ @include rem-fallback(font-size, $h5); }
h6{ @include rem-fallback(font-size, $h6); }


p {
  margin: 0 0 $small-spacing;
}
.p-group{
  position: relative;
}
a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

figure{
  border: 1px solid $light-gray;
  background-color: #fff;
  text-align: center;
  border-radius: $base-border-radius;
  figcaption{
    color: $medium-gray;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-style: italic;
    font-size: $font-small;
    border-top: 1px solid $light-gray;
    //background-color: $light-gray;
    //background: linear-gradient(#fff, $light-gray);
    padding: $pad-y*0.5 $pad-x;
  }
}

p{
  ul > li{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 1em;
    }
  }

}

ul.nostyle, ol.nostyle{
  list-style-type: none;
}
ol.upper-roman{
  list-style-type: upper-roman;
}
ol.upper-alpha{
  list-style-type: upper-alpha;
}
ol.lower-alpha{
  list-style-type: lower-alpha;
}
