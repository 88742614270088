// Bootstrap
/*
@import "bootstrap/scss/functions";
@import "./bootstrap_variables";
@import "bootstrap/scss/mixins";
*/
@import "./_mixins";

$image_url_base: "https://cdn.veloxwheels.com";
$fonts_url_base: "https://cdn.veloxwheels.com/fonts";


// Colors
$dark-blue: #01315a;
$medium-blue: #004daf;
$medium-blue-light: #207CDA;
$blue: #2A91E8;
$light-blue: #55BEFB;
$dark-gray: #1c2b3c;
$medium-gray: lighten($dark-gray, 50%);
$light-gray: #EEEEEE;
$cool-grey: #3d4652;
$cool-gray: $cool-grey;
$main-sidebar-color: $cool-grey;
$main-sidebar-item-hover-background: #00b3ff;

$red: #F96969;
$green: #6DD67A;
$yellow: #FFF68A;
$orange: #F9D547;

$submitted: darken($yellow,30);
$poissued: $blue;
$pending: lighten($green,10);
$cancelled: $red;
$closed: $green;
$shipped: $green;

$brand: $medium-blue;
$action-color: $blue;

$light-gradient: linear-gradient(#eeeeee 0%, #fff 1.5%, #fefefe 3%, #fdfdfd 50%,#efefef 99%, #fefefe 100%);
$subtle-light-gradient: linear-gradient(#F9f9f9 0%, #fafafa 3%, #f8f8f8 85%, #f2f2f2 100%);
$blue-gradient: linear-gradient(to bottom, $medium-blue-light, $blue);
$blue-gradient2: linear-gradient(to bottom, #4d9cff, #0072FF);
$dark-gradient: linear-gradient(lighten($dark-gray,10) 1%, lighten($dark-gray,8) 2%, lighten($dark-gray,5) 50%,lighten($dark-gray,3) 100%);

$theme-colors: (
	"primary": $medium-blue,
	"danger": $red,
	"success": $green,
	"secondary": $medium-blue-light
);

$font-family-base: 'Proxima Nova', Helvetica, Arial, sans-serif;
$fancy-font-family: 'alternate-gothic-no-3-d', 'Proxima Nova', Helvetica, Arial, sans-serif;
$heading-font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;

// Main wrapper width
$main-wrapper-width: 1200px;

// Borders
$base-border-radius: 3px !default;

// Animations
$base-duration: 150ms;
$base-timing: ease;

$container-max-widths: (
  sm: 760px,
  md: 960px,
  lg: 1000px,
  xl: 1200px
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$enable-grid-classes: true !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;
