@import "./variables";
@import "./mixins";

// Buttons
@mixin button($basecolor){
    $btncolor: $basecolor;
    $btncolor-highlight: lighten($btncolor, 30%);
    $btncolor-light: saturate(lighten($btncolor, 15%),20%);
    $btncolor-light1: saturate(lighten($btncolor, 10%),50%);
    $btncolor-dark: darken($btncolor, 20%);

    background: linear-gradient(lighten($btncolor, 10%), $btncolor);
    color: darken($btncolor, 50%);;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    padding: 0.25em 0.5em;
    line-height: 1;
    outline: none;

    border: 1px solid $btncolor-dark;
    border-top-width: 1px;
    border-left-width: 1px;
    border-radius: $base-border-radius;

    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.05), 
        inset 0px 0px 2px 1px rgba(255,255,255,0.25);

    &:hover, &:focus{
        background: linear-gradient($btncolor-light, $btncolor-light1);
	}
    &:focus{
        background: linear-gradient(lighten($btncolor-light1,10), $btncolor-light1);
    }
	&:active{
		background: linear-gradient($btncolor, $btncolor-light1);
		box-shadow: inset 1px 2px 6px rgba(0,0,0,0.25);
        //0px 0px 3px 0px rgba(0,0,0,0.2);
	}
	&:disabled, &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover, &:focus{
            background: linear-gradient(lighten($btncolor, 10%), $btncolor);
        }
        &:active{
            box-shadow: none;
        }

    }
}

.btn-ghost{
    font-family: $fancy-font-family;
    border: 3px solid #fff;
    border-radius: $base-border-radius;
    line-height: 1;
    display: inline-block;
    padding: 0.2em 0.5em;
    font-weight: 100;
    margin-top: 0.2em;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
        background-color: $medium-blue;
    }
}
.velox-btn{
    cursor: pointer;
    font-weight: 600;
    padding: 0.2em 0.5em;
    text-decoration: none;
    transition: background-color $base-duration $base-timing;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    
    @include button(#eee);
    &.blue, .btn-primary {
        @include button($blue);
        color: #fff;
    }
    &.red {
        @include button($red);
        color: #fff;
        background: #ff2c2c;
        &:hover{
            background: darken(#ff2c2c, 10);
        }
    }
    &.green{
        @include button($green);
        color: #fff;
    }
    &.yellow{
        @include button($yellow);
        color: darken($yellow,45%);
        text-shadow: darken($yellow,50%);
    }
    &.sm, .btn-sm{
        @include rem-fallback('font-size', 0.9);
        padding: 0.3em 0.3em;
    }
    &:hover,
    &:disabled, &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            background-color: $action-color;
        }
    }
}

.btn-purchase{
    & > .icon-shopping-cart{
        margin-right: 0.5em;
        box-sizing: border-box;
    }
}

.btn-social{
    cursor: pointer;
    opacity: 0.95;
    &:hover{
        opacity: 1;
    }
}

.social-share-buttons{
    list-style: none;
    padding: 0.5em 0;
    margin: 0;   
    li{
        display: inline-block;
        padding: 0.25em;
        &.label{
            display: block;
            padding: 0;
        }
    }
}

.show-cost-button{
    font-size: 1rem;
    @include button(#eee);
    .icon{
        fill: currentColor;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
    }
    &.hide-cost{
        @include button(#2a2d30);
        color: white;
        &:hover{
            color: white;
        }
    }
    &.show-cost{
        .icon{
            fill: currentColor;
        }
    }
}


.add-to-clipboard-button{
    font-size: 1rem;
    @include button(#eee);
    height: 1.5em;
    width: 1.5em;
    padding: 0;
    .icon{
        fill: #555;
        width: 1em;
        height: 1em;
    }
    &:hover{
        .icon{
            fill: $blue;
        }
    }
}

.add-to-clipboard-button-popover{
    background: #eee;
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0.1em 0.5em;
    color: #333;
    border-radius: 0.3em;
    border: 1px solid #aaa;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 0 4px rgba(0,0,0,0.2);

    &.is-visible{
        opacity: 1;
    }

    .popper-arrow,
    .popper-arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
        border: 1px solid #aaa;
        border-top: 0;
        border-left: 0;
    }

    .popper-arrow {
        visibility: hidden;
        bottom: -4px; // top placement
    }

    .popper-arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

}