@import "../styles/base";
@import "../styles/navigation";
@import "../styles/page_header";
@import "../styles/footer";
@import '../styles/hero';
@import '../styles/expanded-hero';
@import '../styles/sidebar_page';
@import '../styles/order_details';
body{
	flex-direction: column;
}

#main-content{
	width: 100%;
}

@media print{
	body{
		font-size: 12pt;
		-webkit-print-color-adjust: exact;
		margin: 0;
		position: relative;
		z-index: 0;
	}
	@page {
	    size: auto;   /* auto is the initial value */
	    margin: 0.5in;  /* this affects the margin in the printer settings */
	    //padding: 0.5in 1in;
	}
	#adminbar, #site-navigation, #footer{
		display: none;
	}
}
.invalid-feedback{
	display: block;
}

