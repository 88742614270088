@import '../styles/variables';
@import '../styles/order_timeline';

$order-detail-side-padding: 2em;
.order-details{
	&.l-module{
		border: 1px solid #eee;
		margin-top: 2em;
		margin-bottom: 2em;
		.content{
			padding: 1em 1em;
		}
	}
	.label{
		font-weight: bold;
		padding-bottom: 0.5em;
	}
	header, footer{
		background: #fafafa;
		display: flex;
		justify-content: space-between;
		align-items: top;
		padding: 0.5em $order-detail-side-padding;
		.title{
			margin: 0;
			padding-top: 0.25em;
			line-height: 1;
		}
	}
	header{
		border-bottom: 1px solid #efefef;
		align-items: center;
		padding-top: 1em;
		.title{
			color: #3c3c3c;
			text-transform: uppercase;
			font-size: 2em;
		}
	}
	.nav-tabs{
		background: linear-gradient(#fff, #fafafafa);
		margin-top: -1em;
		border-radius: 0;
		.nav-link{
			border-radius: 0;
			&.active{
				border-top: 0;
				border-bottom: 2px solid #fff !important;
			}
		}
	}
	footer{
		border-top: 1px solid #efefef;
		margin-top: 1em;
		display: flex;
		justify-content: space-between;
		.col{
			padding: 1em;
			&:last-child{
				text-align: right;
			}
		}
	}
	.order-summary{
		padding: 2em $order-detail-side-padding;
	}
	.order-store{
		padding: 1em $order-detail-side-padding 2em;
		.store-address{
			padding-bottom: 2em;
			.store-logo{
				width: 120px;
				margin-right: 1em;
				display: inline-block;
				vertical-align: top;
			}
			.address{
				display: inline-block;
				vertical-align: top;
			}
			.location{
				font-weight: bold;
			}
		}
		.notes{
			font-size: 0.9em;
			text-align: left;
			ul{
				margin: 0;
				padding: 0;
				li{
					padding-bottom: 0.5em;
				}
			}
		}
	}
	table{
		border: 1px solid #eee;
	}
	thead, tfoot .row-total td:first-child{
		text-transform: uppercase;
		font-weight: bold;
		color: #aaa;
	}
	tfoot tr td:first-child{
		text-align: right;
	}
	td{
		vertical-align: top;
	}
	th.cell-product{
		padding-left: $order-detail-side-padding + 1em;
	}
	td.cell-product{
		padding: 2em $order-detail-side-padding 1em;
		.product-image{
			width: 12em;
			padding-right: 1em;
		}
		.product-details{
			.item-numbers{
				color: $medium-blue;
				font-size: 1em;
				display: flex;
				text-transform: uppercase;
				.item-number{
					padding-right: 0.5em;
					border-right: 1px solid #eee;
					margin-right: 0.5em;
					height: 1em;
					margin-bottom: 0.5em;
					&:last-child{
						margin-right: 0;
						border-right: 0;
						padding-right: 0;
					}
				}
				label{
					color: lighten($medium-blue, 30);
				}
				.value{
					font-weight: bold;
				}
				.stocking-sku{
					color: darken($green, 30);
					label{
						color: $green;
					}
				}
			}
			label{
				color: #999;
			}
			.lugnuts, .price, .sku{
				font-size: 0.9em;
			}
			.price-note{
				color: #797979;
				font-size: 0.8em;
			}
			.description{
				font-weight: bold;
				margin-top: 0.5em;
				margin-bottom: 0.5em;
			}
			.tracking{
				display: flex;
				align-items: top;
				flex-wrap: wrap;
				.tracking-activity{
					//width: 100%;
				}
			}
		}
		.sku-barcode{
			margin-top: 1.5em;
		}
		.hardware-kit-notes{
			max-width: 400px;
			padding: 0.5em;
			border: 2px solid #ddd;
			border-radius: 0.5em;
			margin-top: 0.5em;
		}
		.no-hardware-kit-notice{
			padding-bottom: 0.25em;
			font-size: 0.9em;
			color: #999;
			strong{
				font-weight: bold;
			}
		}
		.hardware-kit-notice{
			//color: $green;
			font-weight: bold;
			font-size: 1.2em;
			color: $green;
		}
	}
	.tracking-number{
		margin-bottom: 0.5em;
		display: block;
		.icon-circle-triangle{
			width: 1em;
			height: 1em;
			transform:  rotate(180deg);
			transition: transform 0.3s ease-out;
			path, polygon{
				fill: $blue;
			}
		}
	}
	.tracking-details{
		.tracking-activity-event-list{
			display: none;
		}
		.tracking-latest-activity{
			display: block;
		}
		&.is-details-open{
			.tracking-activity-event-list{
				display: block;
			}
			.tracking-latest-activity{
				display:none;
			}
			.tracking-number{
				.icon-circle-triangle{
					transform: rotate(0deg);
					transition: transform 0.3s ease-out;
				}
			}
		}
	}
	.tracking-event-date{
		color: #999;
		font-size: 0.8em;
	}
	.tracking-event-location{
		font-size: 0.9em;
		color: $blue;
	}
	.tracking-activity-event-list{
	    max-height: 12em;
	    max-width: 30em;
		overflow-y: scroll;
		border: 1px solid #eee;
		font-size: 0.9em;
		list-style: none;
		margin: 0;
		padding: 0;
		.tracking-activity-event-list-item{
			padding: 0.5em 1em;
			display: flex;
			align-items: top;
			.tracking-activity-event-aside{
				width: 25%;
				vertical-align: top;
			}
			.tracking-activity-event-main{
				vertical-align: top;
				width: 75%;
			}
		}
		.list-group-item{
			border-radius: 0;
			.row{
				align-items: center;
			}
		}
	}
	.contact-us{
		.logo{
			width: 	200px;
			path{
				fill: #c5c9ce;
			}
			margin-bottom: 0.5em;
		}
		.title{
			font-weight: bold;
			text-transform: uppercase;
			margin: 0;
			padding: 0 0 0.5em;
		}
		.phone{
			font-size: 1.5em;
			font-weight: bold;
		}
	}
	@media( max-width: 1000px ){
		margin-top: 0;
		margin-bottom: 0;
	}
}


.order-detail-page{
	.hero{
		text-align: left;
		padding: 1em;
		background: linear-gradient($medium-blue, darken($medium-blue, 5));
	}
	.breadcrumb{
		background: none;
		margin: 0;
		padding-left: 0;
		.breadcrumb-item{
			color: $blue;
			&.active{
				color: inherit;
			}
		}
	}
	.actions{
		text-align: right;
		.btn{
			text-transform: uppercase;
			font-weight: bold;
			transition: all 0.3s ease;
		}
		.btn-print{
			background: none;
			color: #fff;
			border: 2px solid #fff;
			&:hover{
				background:lighten($medium-blue, 10);
			}
		}
		.btn-activate{
			background: $green;
			color: #fff;
			&:hover{
				background: lighten($green, 10);
			}
		}
	}
}

.order-details{
	.actions{
		.btn{
			text-transform: uppercase;
			font-weight: bold;
			transition: all 0.3s ease;
			display: flex;
			align-items:center;
	
			svg{
				width: 1.5em;
				height: 1.5em;
			}
			.text{
				padding-left: 0.5em;
				letter-spacing: 0.05em;
			}
		}
		.btn-activate{
			background: $green;
			color: #fff;
			&:hover{
				background: lighten($green, 10);
			}
		}
		.btn-print{
			background: #3d4c5d;
			color: #fff;
			&:hover{
				background: lighten(#3d4c5d, 10);
			}
		}
	}
}


// Print styles
.print-header, .print-footer{
	display: none !important;
}
@media print{
	.print-header{
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1em;
		padding-bottom: 2em;
		border-bottom: 3px solid #aaa;
		.page-logo{
			width: 2in;
			.subtitle{
				text-transform: uppercase;
				font-weight: bold;
				color: $medium-blue;
				letter-spacing: 0.05em;
				font-size: 0.9em;
				text-align: center;
			}
		}
		.page-title{
			text-align: right;
			.title{
				font-weight: bold;
				margin: 0;
			}
		}
	}
	.print-footer, footer.print-footer{
		display: flex !important;
		margin-top: 1em;
		padding-top: 2em;
		border-top: 3px solid #aaa;
		background: none !important;
	}
	.hero, .btn-print{
		display: none;
	}
	#main-content{
		padding: 2em;
	}
	a{
		text-decoration: none;
	}
	.order-details{
		line-height: 1.6;
		&.l-module{
			border: 0;
		}
		header{
			display: none;
		}
		.order-summary{
			padding-top: 1em;
			padding-bottom: 2em;
			.order-detail{
				width: 25%;
			}
			.status{
				margin-top: 0.5em;
			}
		}
		td.cell-product .product-image{
			width: 2in;
			height: 2in;
		}
	}
	.tracking-details{
		.tracking-latest-activity{
			display: block;
		}
		.tracking-activity-event-list{
			display: none;
		}
		.tracking-number .icon{
			display: none;
		}
	}
}
