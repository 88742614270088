@import '../../../styles/variables';

@mixin vehicle-wheel {
    position: absolute;
    display: block;
    z-index: 3;
    background: #161616;
    box-shadow: 0 0 12px 10px rgba(0,0,0,0.95);
    //background: radial-gradient(ellipse at center, #161616 0%, #161616 15%, #4a4a4a 16%, #161616 17%, #3a3a3a 25%, #3d424c 26%, #26282b 47%, #000 48%, #000 49%, #000000 100%);
    border-radius: 50%;
    &.is-loading {
        animation-name: wheel-rotate-enter;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
    }
    &.is-not-found{
        background: transparent;
        display: none;
    }
    img{
        z-index: 4;
        animation-name: wheel-rotate;
        animation-duration: 9s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }
    .brakecalipers{
        width: 65%;
        height: 65%;
        background: url($image_url_base + '/ui/brakecaliper.png');
        background-size: cover;
        opacity: 0.3;
        position: absolute;
        top: 17.5%;
        right: 17.5%;
        z-index: 0;
    }
}

.vehicle-wheel {
    @include vehicle-wheel();
}