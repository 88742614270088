// Mixins
@use "sass:math";

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value*16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}

@mixin shadow1{
  box-shadow: 2px 2px 12px 0px rgba(0,0,0,0.1),
    2px 2px 2px 0px rgba(0,0,0,0.1),
    inset 0px 0px 4px 1px rgba(255,255,255,0.25);
}
@mixin shadow2{
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25),
    0px 1px 2px 1px rgba(0, 0, 0, 0.05);
}
@mixin shadow3{
  box-shadow: 2px 4px 36px 0px rgba(0,0,0,0.3),
    0px 1px 24px 0px rgba(0,0,0,0.4),
    inset 0px 0px 4px 1px rgba(255,255,255,0.25);
}
@mixin baseshadow {
    box-shadow: 0 2px 4px darken($base-background-color, 10%);
}

@mixin diagonalborder($size) {
    border-image-source: repeating-linear-gradient(-45deg, white, white 1px, $light-gray 2px, $light-gray 3px);
    border-image-slice: math.div($size, 1px);
    border-width: $size;
}
@mixin diagonalborder-color($size, $color) {
    border-image-source: repeating-linear-gradient(-45deg, white, white 1px, $color 2px, $color 3px);
    border-image-slice: math.div($size, 1px);
    border-width: $size;
}

@mixin button($basecolor){
    $btncolor: $basecolor;
    $btncolor-highlight: lighten($btncolor, 30%);
    $btncolor-light: saturate(lighten($btncolor, 15%),20%);
    $btncolor-light1: saturate(lighten($btncolor, 10%),50%);
    $btncolor-dark: darken($btncolor, 20%);

    background: linear-gradient(lighten($btncolor, 10%), $btncolor);
    color: darken($btncolor, 50%);;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    padding: 0.25em 0.5em;
    line-height: 1;
    outline: none;

    border: 1px solid $btncolor-dark;
    border-top-width: 1px;
    border-left-width: 1px;
    border-radius: $base-border-radius;

    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.05),
        inset 0px 0px 2px 1px rgba(255,255,255,0.25);

    &:hover, &:focus{
        background: linear-gradient($btncolor-light, $btncolor-light1);
  }
    &:focus{
        background: linear-gradient(lighten($btncolor-light1,10), $btncolor-light1);
    }
  &:active{
    background: linear-gradient($btncolor, $btncolor-light1);
    box-shadow: inset 1px 2px 6px rgba(0,0,0,0.25);
        //0px 0px 3px 0px rgba(0,0,0,0.2);
  }
  &:disabled, &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover, &:focus{
            background: linear-gradient(lighten($btncolor, 10%), $btncolor);
        }
        &:active{
            box-shadow: none;
        }

    }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  padding-bottom: math.div($height, $width) * 100%;
  img,
  video,
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
  }
}
