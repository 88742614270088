@import '../../styles/variables';

.wheel-size-options{
    color: $base-font-color;
    font-family: $base-font-family;
    font-feature-settings: "kern", "liga", "pnum";
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height;
    letter-spacing: $base-font-letter-spacing;
    .wheel-options-list{
        list-style: none;
        padding-top: 0.25em;
        .wheel-options-list-item{
            @include rem-fallback(font-size, 1);
            //letter-spacing: 0.1em;
            display: inline-block;
            color: #444;
            border: 2px solid #eee;
            padding: 0;
            margin-right: 0.2em;
            margin-bottom: 0.5em;
            cursor: pointer;
            border-radius: 0.3em;
            box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.2) inset;
            &:hover{
                //background: #fafafa;
                box-shadow: 0 1px 0.1em rgba(0,0,0,0.1);
                color: #333;
            }
            .option-description, .option-stock{
            	padding: 0.5em;
        		label{
        			color: #aaa;
        			text-transform: uppercase;
        			letter-spacing: 0.01em;
        			font-size: 0.8em;
        			font-weight: bold;
        		}
        		.availability{
        			color: #aaa;
        			font-size: 0.8em;
        			line-height: 1.2em;
        		}
            }
            .option-description{
            	background: $light-gradient;
            	border-bottom: 1px solid #ccc;
            	text-align: center;
            	font-weight: bold;
            }
            .option-velox-pn{
            	font-size: 0.9em;
            	font-weight: normal;
            	label{
            		margin-right: 0.5em;
            	}
            }
            .option-stock{
            	display: flex;
            	.stock-online, .stock-instore{
            		width: 8em;
            		text-align: center;
            		padding: 0.5em;
            		margin: 0 auto;
            		label, span{
            			display: block;
            		}
            		label{
            			line-height: 1;
            		}
            		.value{
            			font-weight: bold;
            			font-size: 1.2em;
            		}
            	}
            	.stock-online{
					.value-sku{
						margin-right: 0.5em;
					}
            		.value{
            			color: $blue;
						display: flex;
						flex-direction: row;
						align-items: center;
            		}
            	}
            	.stock-instore{
            		border-left: 1px solid #eee;
            		padding-left: 0.5em;
            		.value{
            			color: $green;
            		}
            	}
            	.availability{
            		text-align: left;
            		font-family: $fancy-font-family;
            		text-transform: uppercase;
            		letter-spacing: 0.05em;
            		padding-top: 0.5em;
            		height: 2em;
            		.icon{
            			width: 2em;
            			height: 2em;
            			float: left;
            			padding-right: 0.5em;
            			path{
            				fill: #aaa;
            			}
            		}
            	}
            }
            &.is-selected{
            	.option-description{
            		background: $medium-blue;
            		color: #fff;
            		label{
            			color: lighten($blue, 30);
            		}
            	}
            }
        }
    }
}
.product-details.is-not-stocking{
	.wheel-options-list-item{
		.option-description{
			border-bottom: 0;
		}
		.option-stock{
			display: none;
		}
	}
}
