@import "./_variables.scss";

@import './reset';
//@import './bootstrap';
//@import "../../node_modules/bootstrap/scss/bootstrap";
//@import "~bootstrap/scss/bootstrap";
@import "./fonts";
@import './grid';

img {
  width: 100%;
  max-height: 100%;
  display: block;
}
.blueprint-overlay{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("https://cdn.veloxwheels.com/bground/blueprint.png"), #143e7b;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    top: 0;
    left: 0;
    z-index: 0;
}
.label{
  text-transform: uppercase;
  color: #aaa;
  @include rem-fallback(font-size, 0.8);
  letter-spacing: 0.1em;
}
td.label{
  display: table-cell;
}

.text-red{
  color: $red;
}
.text-green{
  color: $green;
}
.text-blue{
  color: $blue;
}
.text-gray{
  color: #888;
}

@import "./order_status.scss";
@import "./buttons.scss";
body{
	font-size: 18px;
	line-height: 1.3;
}
h1{
	font-weight: bold;
    letter-spacing: -0.01em;
}
figure{
	padding-top: 1em;
	padding-bottom: 1em;
	figcaption{
	    font-size: 0.9em;
	    line-height: 1.3;
	    padding: 0.5em 0;
	}
}
@import './notes';

#page-error{
  background: #fff;
  padding: 3em;
  max-width: 800px;
  .error-details .wrapper{
    padding: 1em;
  }
  .error-status-num{
    background: #eee;
    padding: 1em;
    text-align: center;
    text-transform: uppercase;
    .error-status{
      font-size: 10em;
      font-family: $fancy-font-family;
      line-height: 1;
    }
  }
}
