@import '../../../styles/variables';

.wheel-slider{
	@import "./slick.scss";
	.slick-slider{
		overflow: hidden;
		position: relative;
		background: linear-gradient(#bbb 0%, #bbb 5%, #ccc 10%, #ddd 25%, $light-gray 50%, lighten($light-gray, 5) 98%, $light-gray 100%);
		border-top: 0;
		border-bottom: 1px solid #ddd;

	    color: $base-font-color;
	    font-family: $base-font-family;
	    font-feature-settings: "kern", "liga", "pnum";
	    font-size: $base-font-size;
	    font-weight: $base-font-weight;
	    line-height: $base-line-height;
	    letter-spacing: $base-font-letter-spacing;
		.slick-list{
			padding: 0 1em;
		}
		.slide{
			outline: none;
		}
		.wheel-image{
			padding: 1em;
			padding-bottom: 0;
			transition: background 0.5s ease;
			cursor: pointer;
			position: relative;
			img{
				//max-width: 10em;
				margin: 0 auto;
				z-index: 3;
				width: 100%;
				display: block;
			}
			&:hover, &:focus, &:active{
				&:after{
					background: linear-gradient(90deg, rgba(255,255,255,0.1), rgba(255,255,255,0.2), rgba(255,255,255,0.1));
					display: block;
				}
			}
			&.is-selected{
				&:after{
					background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.99), rgba(255,255,255,0));
					display: block;
				}
			}
		}
		.slide-details{
			text-align: center;
			padding-bottom: 1em;
			line-height: 1.2;
			@include rem-fallback(font-size, 0.9);
			& > .slide-details-item{
				display: inline-block;
				font-weight: bold;
				color: $dark-gray;
			}
			.wheel-name{
				font-size: inherit;
			}
			.wheel-finish{
				font-size: inherit;
				padding-left: 0.25em;
				padding-right: 0.5em;
				font-weight: normal;
			}
			.wheel-price{
				font-size: inherit;
				color: $blue;
				display: block;
				@media(min-width: 1500px){
					display: inline-block;
				}
			}
		}
	}


	.slider-arrow{
		width: 2em;
		height: 5em;
		display: -ms-flex;
		display: flex;
		box-sizing: border-box;
		background: linear-gradient(#fff, #eee);
		border: 1px solid #aaa;
	    box-shadow: 0 2px 2px 1px rgba(0,0,0,0.1), 0 0 2px rgba(0,0,0,0.1) inset;
	    border-radius: 0.4em;
		padding: 1em 0.5em;
		position: absolute;
		z-index: 5;
		cursor: pointer;
		transition: opacity 0.5s ease;
		line-height: 1;
		.caret{
			max-width: 1em;
			flex: 1;
		}
		&.slider-arrow.slick-prev{
			left: -1px;
			top: 30%;
			padding-right: 1em;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&.slider-arrow.slick-next{
			right: -1px;
			top: 30%;
			padding-left: 1em;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:hover{
			background: #fff;
	    	box-shadow: 0 2px 4px 1px rgba(0,0,0,0.2);
		}
		&:focus{
			background: #fff;
	    	box-shadow: 0 2px 4px 1px rgba(0,0,0,0.2);
		}
	}

	.no-flexbox .slick-slider{
		.wheel-image{
			display: block;
			height: auto;
			width: 100%;
			margin: 0 auto;
			max-height: none;
			max-width: none;
			min-width: none;
			min-height: none;
			img{
				margin: 0;
				width: inherit;
				height: inherit;
			}
		}
	}

}

/*
	.toolbar{
		padding: 0.5em 3em;
		background: linear-gradient(darken($dark-gray,20), darken($medium-gray, 60));
		border-bottom: 1px solid darken($medium-gray, 70);

		.sort-options{
			text-align: left;
			.label{
				display: none;
			}
			.rw-dropdownlist{
				background: linear-gradient(darken($medium-gray, 60), darken($medium-gray, 65));
				color: #aaa;
				border: 1px solid darken($dark-gray, 20);
				box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.5),
					0px 0px 0.8em rgba(0,0,0,0.3),
					inset 0px 0 0.2em rgba(255,255,255,0.1);
				.rw-input{
					color: $medium-gray;
					border: 0;
				}
				.rw-popup{
					background: darken($medium-gray, 60);
					color: inherit;
					border-color: inherit;
				}
			}
		}
	}
*/
