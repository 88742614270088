@import "../styles/variables";

.sidebar-page{
	min-height: 600px;
	position: relative;
	.sidebar-page-sidebar{
		width: 300px;
		background: $medium-blue;
		color: #fff;
		position: absolute;
		left: 0;
		height: 100%;
		overflow-y: scroll;
		transition: all 0.5s ease;
	}
	.sidebar-page-content{
		margin-left: 300px;
	}
	@media( max-width: 1200px ){
		.sidebar-page-sidebar{
			width: 200px;
			font-size: 0.9em;
		}
		.sidebar-page-content{
			margin-left: 200px;
		}
	}
	@media( max-width: 1060px ){
		.sidebar-page-sidebar{
			left: -100%;
		}
		.sidebar-page-content{
			margin: 0 auto;
		}
	}
}

// Sidebar
.sidebar-page-sidebar{
	a{
		display: block;
		line-height: inherit;
		color: inherit;
		&:hover, &:focus{
			color: inherit;
			text-decoration: none;
		}
	}
	.svg-icon{
	    width: 1.5em;
	    height: 1.5em;
	    float: left;
	    margin-right: 0.5em;
	}
	.sidebar-page-sidebar-section{
		padding: 1em 2em;
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
		&:last-child{
			border-bottom: 0;
		}
		&:first-child{
			padding-top: 1.5em;
		}
		&.l-button{
			cursor: pointer;
			transition: background 0.3s ease;
			&:hover{
				background: rgba(0,0,0,0.2);
			}
		}
		@media( max-width: 1200px ){
			padding: 1em;
		}
	}
	.sidebar-page-sidebar-section-title{
		font-weight: bold;
		font-size: 1em;
	}
}
.sidebar-page-sidebar-nav{
	font-weight: bold;
	.sidebar-nav-section{
		margin-top: 1em;
		padding-top: 1em;
		border-top: 2px solid rgba(255, 255, 255, 0.1);
		font-size: 0.9em;
		&:first-child{
			margin-top: 0;
			border-top: 0;
		}
	}
	.sidebar-nav-section-title{
		font-weight: bold;
		font-size: 1em;
		text-transform: uppercase;
	}
	.sidebar-nav-list{
		.nav-item{
			font-size: 0.9em;
			line-height: 1.2;
			padding: 0.4em 0;
			color: lighten($medium-blue, 40);
			transition: color 0.5s ease;
			&:hover{
			color: lighten($medium-blue, 80);
			}
			a{
				display: block;
				line-height: inherit;
				color: inherit;
				&:hover, &:focus{
					color: inherit;
					text-decoration: none;
				}
			}
		}
	}
}
