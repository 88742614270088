@use "sass:math";

#vehicle-selection-form{
	text-align: left;
	font-size: 1em;
	display: flex;
	flex-wrap: wrap;
	.selectize-input{
		padding: 0.5em;
	}
	.selectize-control{
		height: 100%;
	}
	.selectize-control, .selectize-dropdown{
		font-size: inherit;
	}
	.vehicle-selection-form-input{
		overflow: visible;
		padding-right: 0.5em;
	}
	.vehicle-selection-form-input, .btn-submit{
		width: percentage(math.div(1, 5));
	}
	@media(max-width: 960px){
		.vehicle-selection-form-input{
			width: 50%;
			padding-bottom: 0.5em;
			&:nth-child(even){
				padding-right: 0;
			}
		}
		.btn-submit{
			width: 100%;
		}
	}
	@media(max-width: 414px){
		.vehicle-selection-form-input{
			width: 100%;
			padding-right: 0;
		}
	}
}
.hero #vehicle-selection-form{
	.selectize-input{
		padding: 0.8em 1em 0.2em;
		height: 100%;
	}
}

//@import '../styles/vehicle_select_form.css';
#vehicle-selection-form, .velox-vehicle-selection-form{
	.title{
		color: #fff;
		width: 100%;
		display: block;
		text-align: left;
	}
	form{
		width: 100%;
	}
}
