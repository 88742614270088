.wheel-results {
  -ms-flex: 1;
  flex: 1; }
  .wheel-results > .wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto; }
    @media (min-width: 500px) {
      .wheel-results > .wrapper {
        padding: 1em; } }

.no-flexbox .wheel-results > .wrapper {
  display: block;
  position: relative;
  margin: 0;
  width: 100%; }

.no-flexbox .wheel-results .wheel-result {
  display: inline-block; }

.no-flexbox .velox-vehicle-visualizer.is-details-open .wheel-results > .wrapper {
  display: none; }
