#footer {
	//border-top: 1px solid #eee;
	color: lighten($dark-gray, 50);
	padding: 0;
	font-size: 0.9em;
	background: $dark-gray;
	border-top: 0.25em solid lighten($dark-gray, 10);
	position: relative;
	letter-spacing: 0.03em;
	a {
		color: lighten($dark-gray, 50);
		transition: color 0.3s ease;
		text-decoration: none;
		&:hover {
			color: lighten($dark-gray, 80);
			text-decoration: none;
		}
	}
	.footer-company-container {
		color: #fff;
		address {
			.org {
				text-transform: uppercase;
				font-weight: bold;
				padding: 0.5em 0;
			}
			.tel {
				font-size: 1.5em;
				line-height: 1em;
			}
		}
	}
	small {
		letter-spacing: 0.09em;
		color: lighten($dark-gray, 40);
	}
	.logo-partner {
		width: 150px;
		padding-bottom: 0.5em;
		cursor: pointer;
		transition: filter 0.2s ease;
		&:hover {
			filter: brightness(120%) saturate(120%);
		}
	}
	.btn-order {
		color: #fff;
		display: inline-block;
		margin-top: 1em;
		margin-bottom: 0.5em;
		text-transform: uppercase;
		font-weight: bold;
		background: linear-gradient($red, darken($red, 10));
		box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
		border: 1px solid lighten($red, 3);
		&:hover {
			background: linear-gradient(lighten($red, 2), darken($red, 4));
			box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.6), 0px 2px 6px 2px rgba(0, 0, 0, 0.3);
		}
	}
}

.footer-section-header {
	color: rgba(255, 255, 255, 0.8);
	padding: 0.5em 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	margin-bottom: 0.5em;
	text-transform: uppercase;
	font-weight: bold;
}

.footer-nav {
	list-style: none;
	display: block;
	align-items: left;
	margin: 0;
	padding: 0 0 0.5em;
	.nav-item {
		padding: 0;
	}
	&.l-row {
		flex-direction: row;
		.nav-item {
			display: inline-block;
			padding: 0 0.5em;
			border-right: 1px solid lighten($dark-gray, 10);
			&:last-child{
				border-right: 0;
			}
			&:first-child{
				padding-left: 0.5em;
			}
		}
		.nav-link{
			padding: 0;
		}
	}
}

.footer-main {
	padding: 2em 0;
}

.footer-bottom {
	background: rgba(0, 0, 0, 0.2);
	padding: 1em 0;
	text-align: left;
	font-size: 0.8em;
}