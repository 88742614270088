@import './variables';

.price-styled{
	.price-styled-currency{
		padding-right: 0.15em;
	}
	.price-styled-currency, .price-styled-cents{
   		font-size: 0.7em;
   		position: relative;
   		top: -0.25em;
	}
	.price-styled-dot{
		opacity: 0;
	}
	.price-styled-cents{
		position: relative;
		left: -0.2em;
	}
}
