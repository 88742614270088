.page-header{
	background: #eee;
	padding: 0.5em;
	align-items: middle;
	.row{
		justify-content: space-between;
	}
	.breadcrumbs{
		.breadcrumb{
			margin-bottom: 0;
			vertical-align: middle;
			display: flex;
			align-items: center;
			height: 100%;
			font-size: 0.9em;
		}
		.breadcrumb-item{
			margin-bottom: 0;
			overflow: hidden;
			max-width: 400px;
			height: 1.5em;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		@media( max-width: 1200px ){
			.breadcrumb{
				font-size: 0.8em;
			}
		}
		@media( max-width: 860px ){
			.breadcrumb-item{
				max-width: 200px;
			}
		}
		@media( max-width: 688px ){
			.breadcrumb-item{
				max-width: 300px;
			}
		}
		@media( max-width: 460px ){
			.breadcrumb-item{
				max-width: 200px;
			}
		}
		@media( max-width: 357px ){
			.breadcrumb-item{
				max-width: 150px;
			}
		}
	}
	@media print{
		display: none;
	}
}
