@import "./variables";

.ReactModal__Overlay, .react-modal-wrapper{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(rgba(230, 230 ,230, 1), rgba(200, 200, 200, 0.95));
    display: flex;
    align-items: center;
    opacity: 0;
    padding: 1em;
    box-sizing: border-box;
    //overflow: none;
    max-height: 100vh;
    transition: all 0.5s ease;
    &.forefront{
        opacity: 1;
        z-index: 99999;
        .react-modal{
            opacity: 1;
        }
    }
}

.ReactModal__Content, .react-modal{
    position: relative;
    background-color: #fff;
    border-radius: $base-border-radius;
    opacity: 0;
    box-sizing: border-box;
    max-height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    transition: all 0.5s ease;
    box-shadow: 0 2px 6px rgba(0,0,0,0.4);
    outline: 0;
    @media( min-width: 400px ){
        padding: 2em 1em;
    }
    @media( min-width: 500px ){
        padding: $pad-y*2 $pad-x*3;
    }
    @media( min-width: 800px ){
        width: 80%;
        padding: $pad-y*3 $pad-x*3;
    }
    @media( min-width: 1000px ){
        width: 80%;
    }
    .container {
        padding: 0;
    }
    table {
        margin: 0;
        .field {
            width: 33%;
        }
        background-color: #fff;
    }
    img {
        border: 1px solid $medium-gray;
    }
    .modal-close {
        color: $medium-gray;
        position: absolute;
        top: $pad-y*0.5;
        right: $pad-x;
        width: 2rem;
        height: 2rem;
        padding: $pad-y*0.25 $pad-x*0.5;
        text-align: center;
        border-top-right-radius: $base-border-radius;
        border-bottom-right-radius: $base-border-radius;
        appearance: none;
        background: transparent;
        outline: none;
        border: 0;
        svg path{
            fill: $medium-gray !important;
        }
        img{
            border: none;
            opacity: 0.5;
        }
        .fa:before {
            text-shadow: 0px 2px 0px rgba(255, 255, 255, 1);
            font-size: 1.75em;
        }
        &.circle{
            border: 2px solid darken($light-gray,10);
            border-radius: 50%;
            display: flex;
            img{
                flex: 1;
            }
        }
        &:before{
            content: "close";
            text-transform: uppercase;
            font-size: $font-small;
            font-weight: bold;
            display: block;
            position: absolute;
            right: 2rem;
            top: 0;
            padding: $pad-y*0.25 0 $pad-y*0.25 $pad-x*0.5;
            border-top-left-radius: $base-border-radius;
            border-bottom-left-radius: $base-border-radius;
        }
        &:hover {
            color: $blue;
            cursor: pointer;
            //background-color: darken($light-gray,5);
            //border-top-right: 1px solid darken($light-gray, 10);
            //border-bottom-right: 1px solid darken($light-gray, 10);
            svg path{
              fill: $blue !important;
            }
            &:before{
                color: $blue;
                //background-color: darken($light-gray,5);
                height: 2rem;
                border-top-left: 1px solid darken($light-gray, 10);
                border-bottom-left: 1px solid darken($light-gray, 10);
            }
            .svg-path{
                fill: $blue !important;
            }
        }
    }
}
