@keyframes wheel-rotate-enter{
	0% {
		opacity: 0;
		transform: rotate(180deg);
	}
	100% {
		opacity: 1;
		transform: rotate(0deg);
	}
}
@keyframes wheel-rotate{
	0% {
		transform: rotate(180deg);
	}
	30% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-180deg);
	}
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Mixins

@mixin animated($animation, $duration: 1s){
	animation-name: $animation;
	animation-duration: $duration;
 	animation-fill-mode: both;
}

@mixin fadeOutDown($duration: 1s){
	@include animated("fadeOutDown", $duration);
}

@mixin fadeOut($duration: 1s){
  @include animated("fadeOut", $duration);
}

@mixin slideInUp($duration: 1s){
  @include animated("slideInUp", $duration);
}