@import "./variables";
@import "./animations";

*{
	box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-size: 16px;
}

img {
  width: 100%;
  max-height: 100%;
  display: block;
}
.image-wrapper{
	@include aspect-ratio(1,1)
}
.is-not-visible, .is-hidden{
	display: none;
}
.is-visible{
	display: block;
}
@import "./reactwidgets";

@import "./typography";
@import "./buttons";
@import "./loading";
@import "./price";
