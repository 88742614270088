@import '../../../styles/variables';
@import '../../../styles/_mixins';

.visualizer-vehicle {
    width: 100%;
    min-height: 200px;
    overflow: hidden;
    position: relative;
    background: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
	@media( max-width: 410px ){
		min-height: 150px;
	}
	@media( max-width: 310px ){
		min-height: 120px;
	}
    &:after{
        content: "";
        width: 100%;
        height: 15em;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background: linear-gradient(#e2f2ff, #fff, #fff);
    }
    .vehicle-wrapper {
        cursor: pointer;
        position: absolute;
        display: block;
        margin: -25px auto -50px;
        left: 0;
        top: 0;
        position: relative;
        text-align: left;
        width: 100%;
		/*
        img {
          width: 100%;
          height: auto;
          display: block;
        }
		*/
		& > .image-wrapper{
			width: 100%;
			@include aspect-ratio(640,420);
			background: url('https://cdn.veloxwheels.com/ui/vehicle-loading-placeholder.gif') no-repeat center center;
		}
        & > .placeholder{
            position: absolute;
            width: 640px;
            height: 300px;
            top: 0;
            left: 0;
            display: none;
        }
    }
    .vehicle-wrapper.l-mobile{
        position: absolute;
        top: -100%;
        visibility: hidden;
        transition: none;
    }
	&.is-loaded{
		.vehicle-wrapper .image-wrapper{
			background: none;
		}
	}
}

.no-flexbox{
    .visualizer-vehicle{

    }
    .vehicle-color-options{
        @media( min-width: $sidebar-layout-breakpoint ){
            width: 300px;
        }
    }
}

.flexbox .visualizer-vehicle {
    .vehicle-wrapper {

    }
}

.visualizer-vehicle.is-loaded{
    .loading-wrapper{
        opacity: 0;
        z-index: -1;
    }
    .vehicle-wrapper{
        position: relative;
    }
}

.visualizer-vehicle.is-not-visible {
    .vehicle-wrapper.l-mobile {
        //visibility: visible;
        position: fixed;
        opacity: 1;
        top: 0;
        right: 0;
        background: #fff;
        @include shadow3();
        border-bottom: 1px solid #aaa;
        //transition: display 0.25s, top 1s, visibility 0.5s;
        @media( min-width: $horizontal-product-layout-breakpoint) {
            display: none;
            /*
            top: initial;
            bottom: 1em;
            right: 0;
            width: 30em;
            */
        }
    }
}

.velox-vehicle-visualizer-main.is-details-open{
    .visualizer-vehicle.is-not-visible .vehicle-wrapper.l-mobile{
        //visibility: hidden;
    }
}
