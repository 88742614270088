.velox-vehicle-selection-form-dropdown{
	position: relative;
	font-weight: bold;
	font-family: inherit;
	.loader{
		position: absolute;
		max-height: 100%;
		height: 10%;
		width: 3em;
		right: 3em;
		top: 30%
	}
}
