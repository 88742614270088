@import "./variables";
@import "./mixins";

// Buttons
.btn-ghost{
    font-family: $fancy-font-family;
    font-size: $font-base;
    border: 3px solid #fff;
    border-radius: $base-border-radius;
    line-height: 1;
    display: inline-block;
    padding: 0.2em 0.5em;
    font-weight: 100;
    margin-top: 0.2em;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
        background-color: $medium-blue;
    }
}

input[type="button"], input[type="submit"], input[type="reset"], .btn, a.btn {
    appearance: none;
    color: #000;
    cursor: pointer;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    line-height: 1.3;
    padding: 0.2em 0.5em;
    text-decoration: none;
    transition: background-color $base-duration $base-timing;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;

    @include button(#eee);
    &.blue {
        @include button($blue);
        color: #fff;
    }
    &.red {
        @include button($red);
        color: #fff;
        background: #ff2c2c;
        &:hover{
            background: darken(#ff2c2c, 10);
        }
    }
    &.blue {
        @include button($blue);
        color: #fff;
    }
    &.green{
        @include button($green);
        color: #fff;
    }
    &.yellow{
        @include button($yellow);
        color: darken($yellow,45%);
        text-shadow: darken($yellow,50%);
    }
    &.sm{
        font-size: $font-small;
        padding: 0.3em 0.3em;
    }
    &:hover,
    &:focus {
       // background-color: shade($action-color, 20%);
       // color: #fff;
    }
    &:disabled, &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            background-color: $action-color;
        }
    }
}

.input-group{
    display: block;
    position: relative;
    input, .btn{
        height: 3em;
        padding: 0.2em 0.5em;
        margin: 0 !important;
        display: block;
    }
    .form-input{
        padding: 0;
    }
    input{
        border-top-left-radius: $base-border-radius;
        border-bottom-left-radius: $base-border-radius;
        padding-right: 2.75em;
    }
    .btn{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: 0;
        font-weight: normal;
        position: absolute;
        right: 0;
        top: 0;
        width: 2.75em;
    }
	.rw-dropdown-list-input{
		input{
			height: auto;
			padding: 0;
			margin: 0;
		}
	}
}


.btn-purchase{
    & > .icon-shopping-cart{
        margin-right: 0.5em;
        box-sizing: border-box;
    }
}

.btn-social{
    cursor: pointer;
    opacity: 0.95;
    &:hover{
        opacity: 1;
    }
}

.social-share-buttons{
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
    li{
        display: inline-block;
        padding: 0.25em;
        &.vxviz-label{
            display: block;
            padding: 0;
        }
    }
}
