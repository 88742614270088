$middle-icon-top: 8px;
$last-icon-top: 16px;

#navbar-toggle{
	.hamburger-icon{
		width: 24px;
		height: 18px;
		margin: 0.5em;
		position: relative;
		transition: 0.5s ease-in-out;
		transform: rotate(0deg);

		.icon-bar{
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: $blue;
			border-radius: 9px;
			opacity: 1;
			left: 0;
			transition: 0.25s ease-in-out;
			transform: rotate(0deg);
			&:nth-child(1) {
				top: $middle-icon-top;
				width: 0%;
				left: 50%;
				opacity: 0;
			}
			&:nth-child(2){
				top: $middle-icon-top;
				transform: rotate(45deg);
			}
			&:nth-child(3){
				top: $middle-icon-top;
				transform: rotate(-45deg);
			}
			&:nth-child(4){
				top: $middle-icon-top;
				width: 0%;
				left: 50%;
				opacity: 0;
			}
		}
	}
	&.collapsed{
		.hamburger-icon{
			.icon-bar{
				background: #c6cace;
				&:nth-child(1) {
					width: 100%;
					top: 0px;
					transform: rotate(0deg);
					left: 0;
					opacity: 1;
				}
				&:nth-child(2), &:nth-child(3){
					width: 100%;
					top: $middle-icon-top;
					transform: rotate(0deg);
				}
				&:nth-child(4){
					width: 100%;
					top: $last-icon-top;
					transform: rotate(0deg);
					left: 0;
					opacity: 1;
				}
			}
		}
	}
}
