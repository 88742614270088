@use "sass:math";

@import '../../../styles/variables';

@keyframes growshrink {
	0% {
		opacity: 0;
		transform: rotate(360deg);
	}
	50% {
		opacity: 1;
		transform: rotate(0deg);
	}
	100% {
		opacity: 0;
		transform: rotate(-360deg);
	}
}

.strike{
    text-decoration: line-through;
}

.wheel-image-placeholder{
	width: 100%;
	svg{
		width: 100%;
		height: 100%;
		path{
			fill: rgba(0,0,0,0.1);
		}
		.wheel-1, .wheel-2{
			transform-origin: center;
			animation-name: growshrink;
			animation-duration: 3s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}
}

.wheel-image{
	position: relative;
    z-index: 2;
	.image-wrapper{
		background: url('https://cdn.veloxwheels.com/product-images/wheel-loading-placeholder-600.png') no-repeat center center;
		background-size: contain;
	}
	img{
		transition: all 0.5s ease;
		opacity: 0;
		width: 100%;
		height: auto;
		max-width: initial;
		max-height: initial;
	}
	&.is-loaded{
		.image-wrapper{
			background: none;
		}
		.placeholder{
			opacity: 0;
		}
		img{
			opacity: 1;
		}
	}
}
.no-flexbox{
    .wheel-image{
        height: auto;
        width: 100%;
        max-height: none;
        min-width: none;
        min-height: none;
        img{
            margin: 0;
            width: inherit;
            height: inherit;
        }
    }
}
.wheel-result {
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    padding: 0.25em 0.5em;
    @media( min-width: $horizontal-product-layout-breakpoint) {
        padding: 0.5em 0.5em;
        width: 50%;
        border: 0;
    }
    @media( min-width: 600px) {		width: percentage(math.div(6, 12)); 	}
    @media( min-width: 1000px) {    width: 50%;				    }
    @media( min-width: 1200px) {	width: percentage(math.div(4, 12)); 	}
    @media( min-width: 1300px) {    width: 25%;     			}
    @media( min-width: 1600px) {    width: 20%;					}
    @media( min-width: 1900px) {    width: 16.667%;   			}
    &:last-child {
        .wrapper {
            border-bottom-width: 1px;
        }
    }
}

.wheel-result > .wrapper{
    dislay: -ms-flex;
    display: flex;
    border-radius: 0.2em;
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    @media( min-width: $horizontal-product-layout-breakpoint) {
        display: block;
        padding-bottom: 0;
    }
    .wheel-series-logo{
    	width: 100px;
    	height: 2em;
    	z-index: 10;
    	img{
    		width: auto;
    		height: 100%;
    	}
    	position: absolute;
	    left: 0;
	    bottom: 0;
    	padding: 0.5em;
    	//background: #eee;
	    @media( min-width: $horizontal-product-layout-breakpoint ){
	    	top: 0;
	    	bottom: initial;
	    }
    }
    .wheel-image{
        width: 40%;
        @media( min-width: $horizontal-product-layout-breakpoint) {
        	width: 100%;
			margin: 0 auto;
        }
    }
    .wrapper-side{
        text-align: left;
        padding-left: 1em;
        width: 60%;
        @media( min-width: $horizontal-product-layout-breakpoint) {
            text-align: center;
            padding-left: 0;
        	padding-bottom: 2em;
            width: 100%;
        }
        .wheel-result-overview{
            width: 100%;
        }
    	@media( min-width: $horizontal-product-layout-breakpoint) {
	        .wheel-price{
	        	position: absolute;
	        	bottom: 0;
	        	left: 0;
	        	width: 100%;
	        	height: 2em;
	        	padding: 0.25em 1em 0.5em;
	        }
	    }
    }
}

// Theming
.wheel-result > .wrapper{
    background: #fff;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
    &:hover{
        box-shadow: 0 0 1em 1px rgba(0,0,0,0.1), 0 0 0.3em 2px rgba(0,0,0,0.1);
    }
    .wheel-image{
	    background: linear-gradient(#fff 0%, #eee 1px, #ddd 1%, #ddd 30%, #fafafa 75%, #fefefe 100%);
	    padding: 0.7em;
	    height: 100%;
	    border-right: 1px solid #eee;
	    @media( min-width: $horizontal-product-layout-breakpoint ){
	    	height: auto;
	    	border-bottom: 1px solid #eee;
	    }
    }
}
.wheel-result-overview{
    //position: relative;
    padding-bottom: 0.5em;
    @media( min-width: $horizontal-product-layout-breakpoint) {
        width: 100%;
    }
    .wheel-main{
        padding-top: 1em;
        @media( min-width: $horizontal-product-layout-breakpoint) {
            padding-top: 0.5em;
        }
    }
    .wheel-model{
        font-weight: bold;
        margin: 0;
        @include rem-fallback(font-size, 1.3);
    }
    .wheel-finish{
        line-height: 1;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        text-transform: uppercase;
        font-weight: bold;
        color: #888;
        @include rem-fallback(font-size, 0.8);
        &.long{
            @include rem-fallback(font-size, 0.75);
        }
    }
    .wheel-series{
        line-height: 1.333;
        @include rem-fallback(font-size, 0.7);
        color: #bbb;
    }
    .unit-price{
        @include rem-fallback(font-size, 1);
        padding-top: 0.25em;
        margin-top: 0.5em;
        border-top: 1px solid #eee;
        .discount-price, .original-price{
            display: inline-block;
        }
        .discount-price{
            font-weight: bold;
            color: $blue;
        }
        .original-price{
            color: #bbb;
            padding-left: 0.5em;
            .note{
            	padding-right: 0.3em;
            }
        }
        .note, .unit{
            @include rem-fallback(font-size, 0.75);
        }
        &.is-not-discount{
        	.original-price{
        		color: $blue;
        	}
        }
    }
}
.wheel-actions{
	padding-bottom: 1em;
    .btn-details.btn.sm{
        @include rem-fallback(font-size, 0.7);
        font-size: 0.8em;
        padding: 0.5em 1em;
    }
    @media( min-width: $horizontal-product-layout-breakpoint) {
        display: none;
    }
}

.wheel-result.is-viewed{
    /*
    .wheel-image:before{
        content: "";
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background: $blue;
        position: absolute;
        top: 0;
        left: 0;
    }
    */
    /*
    .wheel-model{
        color: $blue;
    }
    */
}

.premium-label1{
	display: none;
	color: #fff;
	//background: linear-gradient(#d00606,#d00606);
	width: 100px;
	z-index: 10;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0.5em;
	margin-top: 0.2em;
	font-size: 0.6em;
	line-height: 1;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.08em;
	@media( min-width: $horizontal-product-layout-breakpoint ){
		top: 0;
		bottom: initial;
		font-size: 0.8em;
	}
	.is-visible{
		display: block;
	}
}

.premium-label{
	display: none;
	color: #fff;
	z-index: 10;
	position: absolute;
	width: 5em;
	right: -0.4em;
	top: 0;
	margin-top: 0.5em;
	line-height: 1;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.08em;
	@media( max-width: $horizontal-product-layout-breakpoint ){
		top: 0;
		bottom: initial;
		width: 90px;
		right: -7px;
	}
	.is-visible{
		display: block;
	}
}
