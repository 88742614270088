.icon-loading-grid{
	fill: $blue;
}

.icon-loading-spin{
	stroke: $blue;
	path{
		stroke: lighten($light-blue, 20);
	}
	max-width: 10em;
}