// Navigation
@import './hamburger_icon.scss';

// Header Navigation Brand
#vx-navigation-logo {
	padding: 0.6em;
	align-items: center;
	display: flex;
	.image-wrapper {
		width: 200px;
	}
	svg.logo{
		width: 200px;
		height: 36.59px;
		path, text{
			fill: $medium-blue;
			font-weight: bold;
		}
	}
}

#partner-logo {
	align-items: center;
	height: 100%;
	padding: 1em;
	.image-wrapper {
		width: 120px;
		margin-left: auto;
	}
}

// Adminbar
#adminbar{
	background: darken($medium-blue, 20);
	color: #fff;
	//height: 2em;
	font-size: 0.9em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	.adminbar-item{
		padding: 0.25em;
	}
	.partner-info{
		//display: flex;
		#partner-logo, .partner-store{
			display: inline-block;
			vertical-align: middle;
		}
	}
	.partner-store{
		background: rgba(0,0,0,0.4);
		padding-left: 1em;
		padding-right: 1em;
	}
	#partner-logo{
		padding: 0;
		margin-left: 1em;
		.image-wrapper{
			width: 90px;
		}
	}
	.account-info{
		padding-right: 4em;
		.account-user, .account-logout, .account-rank{
			display: inline-block;
			vertical-align: top;
		}
		.account-rank{
			position: absolute;
			top: 0;
			right: 1em;
			z-index: 1000;
			.rank{
				width: 2.5em;
				height: 3em;
				.rank-polygon {
					fill: #ec1700;
				}

				.rank-label {
					font-size: 20px;
				}
				.rank-value{
					font-size: 58px;
				}

				.rank-label,
				.rank-value {
					fill: #fff;
					font-weight: 700;
				}
			}
			&.is-length-2{
				.rank .rank-value{
					font-size: 52px;
				}
			}
			&.is-length-3{
				.rank .rank-value{
					font-size: 40px;
				}
			}
			&.is-top10{
				.rank-polygon{
					fill: #ecc100; // yellow-gold
				}
			}
		}
	}
}
.promotion-banner{
	line-height: 1;
	padding: 1em;
	margin-left: auto;
}
.banner-image, #promotion-banner{
	background: #f72f2f;
	color: #fff;
	border: 1px solid rgba(255,255,255,0.5);
	font-size: 0.9em;
	//display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	//padding: 0.5em;
	width: 234px;
	height: 60px;
	text-transform: none;
	line-height: 1.2;
	img{
		display: block !important;
	}
	.promotion-discount{
		font-weight: bold;
		padding-bottom: 0.25em;
	}
	.promotion-details-message, .promotion-details-dates{
		font-size: 0.7em;
		font-weight: normal;
		padding-bottom: 0.25em;
	}
	.super {
		font-size: 0.6em;
		position: relative;
		bottom: 0.4em;
		text-transform: uppercase;
		padding-left: 0.2em;
	}
	@media(max-width: 1200px){
		width: 12em;
		.promotion-details-message{
			display: none;
		}
	}
}

// Navigation
#vx-header-navigation-brand {
	background: #fff;
	color: #111;
	.contact-info {
		line-height: 1;
		padding: 1em;
		margin-left: auto;
		.contact-info-title {
			font-size: 0.7em;
		}
		.contact-info-link {
			a {
				color: inherit;
			}
		}
		.contact-info-phone {
			font-size: 1em;
		}
		.contact-info-email {
			font-size: 0.8em;
		}
	}

	.main-navigation {
		text-transform: uppercase;
		padding: 0 1em;
		&.navbar-nav {
			flex-direction: row;
		}
		&.nav {
			//width: 100%;
			justify-content: space-between;
			flex-direction: row;
			.nav-item {
				color: lighten($dark-gray, 10);
				font-weight: bold;
				position: relative;
				display: flex;
				flex: 1;
				align-items: center;
				padding: 0 0.5em;
				transition: color 0.3 ease;
				&.dropdown{
					.dropdown-menu{
						position: absolute !important;
						transform: translate3d(0, 4em, 0px);
						top: 0px;
						left: 0px;
						will-change: transform;
						.dropdown-item{
							//padding: 0.5em;
							font-weight: bold;
						}
					}
				}

				&:hover {
					color: $medium-blue;
				}
				.nav-link {
					color: inherit;
				}
				&.active {
					font-weight: bold;
					color: #000;
					a:after {
						content: "";
						display: block;
						width: 100%;
						height: 2px;
						position: absolute;
						left: 0;
						background: lighten($medium-blue, 25);
					}
				}

			}

		}
	}

	@media( max-width: 1200px) {
		.contact-info {
			font-size: 0.9em;
			line-height: 1.3;
		}
		#partner-logo {
			padding-left: 0.5em;
			padding-right: 1em;
		}
		#vx-navigation-logo {
			.image-wrapper {
				width: 150px;
			}
		}
	}
}

#navbar-toggle{
	background: transparent;
	border: none;
}

.site-navigation {
	background: #fff; //border-bottom: 1px solid rgba(0,0,0,0.2);
	box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.09);
	position: relative;
	z-index: 100;
}

#vx-navigation {
	border-top: 1px solid #eee;
	display: none;
	position: relative;
	z-index: 100;
	.main-navigation {
		text-transform: uppercase;
		.navbar-nav {
			flex-direction: row;
		}
		&.nav {
			width: 100%;
			justify-content: space-between;
			.nav-item {
				color: #999;
				position: relative;
				&:hover {
					color: #000;
				}
				.nav-link {
					color: inherit;
				}
				&.active {
					font-weight: bold;
					color: #000;
					&:before {
						content: " ";
						display: block;
						width: 4em;
						height: 0.25em;
						background: $blue;
						position: absolute;
						top: -0.5rem;
					}
				}
			}
		}
	}
	@media( max-width: map-get($grid-breakpoints, "sm")) {
		padding: 0;
		position: relative;
		.main-navigation {
			text-align: center;
			font-size: 1.5em;
			background: darken($medium-blue, 10);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			&.nav {
				.nav-item {
					color: lighten($medium-blue, 40);
					border-bottom: 1px solid rgba(0,0,0,0.1);
					position: relative;
					font-weight: bold;
					&:hover{
						background: darken($medium-blue, 5);
						color: #fff;
					}
					&.active {
						color: #fff;
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media(max-width: 996px) {
	#vx-header-navigation-brand .main-navigation {
		display: none;
	}
	#vx-navigation {
		display: block;
	}
}


.draft-order-search-form{
	display: flex;

	label{
	  background-color: #A4161A;
	  color: white;
	  width: 3em;
	  border: darken(#A4161A, 20%);
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	  svg{
		width: 100%;
		height: 100%;
		polygon, path{
			fill: #ffffff;
		}
	  }
	}
	input{
		min-width: 10em;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-weight: bold;
		color: #3d4652
	}
  }

// Order Steps

#order-steps {
	background: #3d4652; //background: linear-gradient(#eee, #efefef, #ddd);
	border-top: 1px solid #e2e2e2;
	color: #b1b1b1;
	text-transform: uppercase;
	padding: 0;
	font-family: $fancy-font-family;
	letter-spacing: 0.1em;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
	z-index: 100;
	.container {
		padding: 0;
	}
	.order-steps-list {
		width: 100%;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;
		@media( max-width: map-get($grid-breakpoints, "md")) {
			font-size: 0.9em;
		}
	}
	.order-steps-list-item {
		padding-left: 3em;
		width: 100%;
		height: 3em;
		display: flex;
		align-items: center;
		line-height: 1;
		cursor: default;
		border-left: 1px solid rgba(0, 0, 0, 0.2);
		svg {
			polygon {
				fill: #3d4652;
			}
		}
		&:last-child {
			border-right: 1px solid rgba(0, 0, 0, 0.2);
		}
		&.is-active {
			background: lighten($medium-blue, 15);
			color: #fff;
		}
		&.is-previous {
			background: $medium-blue;
			color: lighten($medium-blue, 25);
			&:hover {
				background: lighten($medium-blue, 5);
				color: lighten($medium-blue, 40);
			}
		}
		a,
		.nav-link {
			padding: 0.6em 1em;
			cursor: pointer;
			color: inherit;
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
	.order-steps-list-item-number {
		display: block;
		display: flex;
		align-items: center;
		width: 1em;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		vertical-align: middle;
		text-align: center;
		font-weight: bold;
		padding: 0.6em 1em;
		background: rgba(0, 0, 0, 0.1);
	}
}
.nav-link, .dropdown-toggle{
	outline: none;
}
