.hero{
	padding: 4em 1em 5em;
	color: #fff;
	text-align: center;
	background: linear-gradient(darken($medium-blue, 5),$medium-blue,darken($medium-blue, 10));
	background-size: cover;
	background-position: center;
	z-index: -1;
	.title{
		font-weight: bold;
	}
	.container{
		z-index: 30;
		position: relative;
	}
	@media( max-width: 500px ){
		.title{
			font-size: 1.4em;
		}
	}
	@media( max-width: 420px ){
		padding: 4em 0 4em;
		.search{
			.search-results-menu{
				font-size: 0.8em;
			}
		}
	}

}

.expanded-hero{
	#vx-navigation-logo{
		svg.logo{
			path, text{
				fill: #ffffff;
			}
		}
	}
	.site-navigation{
		position: absolute;
		width: 100%;
		background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0));
		color: #fff;
		box-shadow: none;
	}
	#vx-header-navigation-brand{
		background: inherit;
		color: inherit;
		.main-navigation.nav .nav-item{
			color: inherit;
			padding: 0 1.5em;
		}
	}
	.hero{
		padding: 8em 0 3em;
	}
	@media(max-width: 996px) {
		#vx-navigation{
			background: darken($medium-blue, 10);
			height: 3em;
			border-top: 0;
			.main-navigation.nav .nav-item{
				color: #fff;
			}
		}
		.hero{
			padding-top: 12em;
		}
	}
	@media( max-width: map-get($grid-breakpoints, "sm")) {
		.hero{
			padding-top: 8em;
		}
		#vx-header-navigation-brand{
			border-bottom: 1px solid darken($medium-blue, 10);
			#vx-navigation-logo{
				padding: 1em;
			}
		}
		#vx-navigation{
			display: none;
		}
	}
}
