@import "./variables";
.accordion {
    padding: 0;
    background: #ffffff;
    color: darken($medium-gray, 25);
    letter-spacing: 0.05em;
    .accordion-header {
        @include rem-fallback(font-size, 1);
        font-weight: bold;
        text-transform: uppercase;
        color: inherit;
        padding: 0.5em 0.25em 0.25em 1.25em;
        margin-bottom: 0;
        background: inherit;
        border-top: 1px solid rgba(0,0,0,0.2);
        position: relative;
        cursor: pointer;
        a {
            color: inherit;
        }
        .caret {
            height: 0.9em;
            width: 0.9em;
            position: absolute;
            top: 0.9em;
            left: 0;
            .caret-fill,
            .caret-stroke {
                fill: rgba(255,255,255,0.25);
            }
        }
    }
    .accordion-menu {
        background: inherit;
        color: currentColor;
        margin: 0;
        padding: 0 0 1em;
        .accordion-item {
            position: relative;
            align-items: center;
            margin: 0;
        	background: inherit;
            border: none;
            padding: 0.5em 1.5em;
            line-height: 1.2;
            overflow:  hidden;
            @include rem-fallback(font-size, 0.9);
            text-overflow: ellipsis;
            cursor: pointer;
            opacity: 0.8;
            display: block;
            &:hover{
                opacity: 1;
            }
            input{
                display: none;
                &:checked+label:before {
                    content: "\02714";
                    border-color: $blue;
                    background: $blue;
                    color: #fff;
                }
            }
            & > .vxviz-sidebar-label, .checkbox-label{
            	color: inherit;
                line-height: 1.3;
                display: flex;
			    &:before{
                    display: block;
			    	font-style: normal;
				    font-weight: bold;
				    text-align: center;
				    -mos-osx-font-smoothing: grayscale;
				    -webkit-font-smoothing: antialiased;
				    content: " ";
                    @include rem-fallback(width, 1.3);
                    @include rem-fallback(height, 1.3);
                    @include rem-fallback(min-width, 1.3);
                    @include rem-fallback(min-height, 1.3);
                    @include rem-fallback(padding, 0.1);
				    margin: 0;
				    border: 1px solid #c2cfd6;
				    background-color: #fff;
				    border-radius: 0.1em;
				    color: #fff;
				    font-size: 16px;
				    line-height: 1.2;
				    text-align: center;
				    vertical-align: middle;
                    box-sizing: border-box;
			    }
                .accordion-item-value{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    @include rem-fallback(padding-left, 0.5);
                    display: block;
                }
            }
        }
    }
    &.dark {
        background: $dark-gray;
        color: #fff;
    }
    &.accordion-inherit{
    	background: inherit;
    	color: inherit;
    }
    & > .accordion-item{
        overflow: hidden;
        display: block;
    	& > .accordion-menu{
            display: none;
            overflow: hidden;
    	}
		&.active{
			& > .accordion-menu{
				height: initial;
				opacity: 1;
                position: relative;
                display: block;
			}
		}
	}
}