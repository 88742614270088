@import "../../styles/variables";
@import "../../styles/animations";

.velox-visualizer-main {
	position: relative;
    width: 100%;
    overflow: hidden;
    &.has-no-vehicle{
        .wheel-results{
            height: 100%;
        }
    }
    &.is-not-loaded {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1em;
        &.has-vehicle, &.has-results{
            display: block;
            padding: 0;
            text-align: left;
        }
        .visualizer-vehicle > .vehicle-wrapper{
            position: relative;
            right: -100%;
            transition: right 0.25s, opacity 1s;
            opacity: 0;
            min-height: 300px;
        }
        .icon-loading{
            max-width: 5em;
        }
    }
    &.is-loaded {
        .visualizer-vehicle > .vehicle-wrapper{
            right: 0;
            opacity: 1;
            min-height: initial;
        }
    }
    & > .vehicle-color-options{
        display: none;
        @media( min-width: $sidebar-layout-breakpoint ){
            display: block;
            position: absolute;
            top: 0;
            margin-left: 1em;
            margin-top: 1em;
        }
    }
    .visualizer-vehicle{
        z-index: 2;
    }
}

.is-not-details-open{
    .product-details{
        display: block;
    }
    .wheel-result-grid{
        transition: all 0.5s ease;
        position: relative;
        opacity: 1;
    }
    .wheel-details{
        position: absolute;
        bottom: -100%;
        opacity: 0;
        transition: all 2s ease;
        @include fadeOut();
    }
}
.is-details-open{
    .product-details{
        display: block;
    }
    .wheel-result-grid{
        position: absolute;
        opacity: 0;
        transition: all 1s ease;
        display: none;
        @include fadeOutDown();
    }
    .wheel-details{
        position: relative;
        bottom: 0;
        opacity: 1;
        transition: all 0.5s ease;
        @include slideInUp(1s);
    }
    .velox-visualizer-main.has-no-vehicle{
        .wheel-results{
            height: auto !important;
        }
    }
}
.velox-visualizer-main.has-no-results{
    .wheel-results{
        display: flex;
        background: #fff;
        padding: 2em;
    }
    .no-results-vehicle-image{
        display: none;
        @media( min-width: 700px ){
            max-width: 600px;
            display: block;
        }
    }
    .no-results-message{
        text-align: left;
        max-width: 700px;
        & > .title{
            color: $dark-gray;
        }
        & > .title-2{
            color: #6a6e7b;
        }
        & > .title-2{
            color: $blue;
        }
    }
}