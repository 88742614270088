.product-image-slider {
	/* Slider */
}

.product-image-slider .slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.product-image-slider .slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}

.product-image-slider .slick-list:focus {
	outline: none;
}

.product-image-slider .slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.product-image-slider .slick-slider .slick-list,
.product-image-slider .slick-slider .slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.product-image-slider .slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
}

.product-image-slider .slick-track:after,
.product-image-slider .slick-track:before {
	content: "";
	display: table;
}

.product-image-slider .slick-track:after {
	clear: both;
}

.slick-loading .product-image-slider .slick-track {
	visibility: hidden;
}

.product-image-slider .slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir="rtl"] .product-image-slider .slick-slide {
	float: right;
}

.product-image-slider .slick-slide img {
	display: block;
}

.product-image-slider .slick-slide.slick-loading img {
	display: none;
}

.product-image-slider .slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .product-image-slider .slick-slide {
	display: block;
}

.slick-loading .product-image-slider .slick-slide {
	visibility: hidden;
}

.slick-vertical .product-image-slider .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.product-image-slider .slick-arrow.slick-hidden {
	display: none;
}

.product-image-slider .slick-slider {
	overflow: hidden;
	position: relative;
	background: inherit;
	border-top: 0;
	border-bottom: 1px solid #ddd;
	color: #1c2b3c;
	font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
	font-feature-settings: "kern", "liga", "pnum";
	font-size: 18px;
	font-weight: 400;
	line-height: 1.6666;
	letter-spacing: 0.01em;
}

.product-image-slider .slick-slider .slick-list {
	padding: 0 1em;
}

.product-image-slider .slick-slider .slide {
	outline: none;
}

.product-image-slider .slick-slider .wheel-image {
	padding: 1em 1em 0;
	transition: background 0.5s ease;
	cursor: pointer;
	position: relative;
}

.product-image-slider .slick-slider .wheel-image img {
	max-width: 10em;
	margin: 0 auto;
	z-index: 3;
	width: 100%;
	display: block;
}

.product-image-slider .slick-slider .wheel-image:active:after,
.product-image-slider .slick-slider .wheel-image:focus:after,
.product-image-slider .slick-slider .wheel-image:hover:after {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
	display: block;
}

.product-image-slider .slick-slider .wheel-image.is-selected:after {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0));
	display: block;
}

.product-image-slider .slick-slider .slide-details {
	text-align: center;
	padding-bottom: 1em;
	line-height: 1.2;
	font-size: 14.4px;
	font-size: 0.9rem;
}

.product-image-slider .slick-slider .slide-details > .slide-details-item {
	display: inline-block;
	font-weight: bold;
	color: #1c2b3c;
}

.product-image-slider .slick-slider .slide-details .wheel-name {
	font-size: inherit;
}

.product-image-slider .slick-slider .slide-details .wheel-finish {
	font-size: inherit;
	padding-left: 0.25em;
	padding-right: 0.5em;
	font-weight: normal;
}

.product-image-slider .slick-slider .slide-details .wheel-price {
	font-size: inherit;
	color: #006ffc;
	display: block;
}
@media (min-width: 1500px) {
	.product-image-slider .slick-slider .slide-details .wheel-price {
		display: inline-block;
	}
}

.product-image-slider .slider-arrow {
	width: 2em;
	height: 5em;
	display: -ms-flex;
	display: flex;
	box-sizing: border-box;
	background: linear-gradient(#fff, #eee);
	border: 1px solid #aaa;
	box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1) inset;
	border-radius: 0.4em;
	padding: 1em 0.5em;
	position: absolute;
	z-index: 5;
	cursor: pointer;
	transition: opacity 0.5s ease;
	line-height: 1;
	svg{
		width: inherit;
		height: inherit;
	}
}

.product-image-slider .slider-arrow .caret {
	max-width: 1em;
	flex: 1;
}

.product-image-slider .slider-arrow.slider-arrow.slick-prev {
	left: -1px;
	top: 30%;
	padding-right: 1em;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.product-image-slider .slider-arrow.slider-arrow.slick-next {
	right: -1px;
	top: 30%;
	padding-left: 1em;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.product-image-slider .slider-arrow:hover {
	background: #fff;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.product-image-slider .slider-arrow:focus {
	background: #fff;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.product-image-slider .no-flexbox .slick-slider .wheel-image {
	display: block;
	height: auto;
	width: 100%;
	margin: 0 auto;
	max-height: none;
	max-width: none;
	min-width: none;
	min-height: none;
}

.product-image-slider .no-flexbox .slick-slider .wheel-image img {
	margin: 0;
	width: inherit;
	height: inherit;
}
