@import './variables';

.expanded-hero{
	#vx-navigation-logo{
		svg.logo{
			path, text{
				fill: #ffffff;
			}
		}
	}
	.site-navigation{
		position: absolute;
		width: 100%;
		background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0));
		color: #fff;
		box-shadow: none;
	}
	#vx-header-navigation-brand{
		background: inherit;
		color: inherit;
		.main-navigation.nav .nav-item{
			color: inherit;
			padding: 0 1em;
		}
	}
	.hero{
		padding: 8em 0 3em;
	}
	#navbar-toggle{
		background: transparent;
		border: none;
		color: #fff;
		&.collapsed .hamburger-icon .icon-bar{
			background: #fff;
		}
	}
	@media(max-width: 996px) {
		#vx-navigation{
			background: darken($medium-blue, 8);
			height: 3em;
			border-top: 0;
			.main-navigation.nav .nav-item{
				color: #fff;
			}
		}
		.hero{
			padding-top: 11em;
		}
	}
	@media( max-width: map-get($grid-breakpoints, "sm")) {
		#adminbar{
			.partner-store, .account-rank{
				display: none;
			}
			.account-info{
				padding-right: 0.5em;
			}
		}
		.hero{
			padding-top: 8em;
		}
		#vx-header-navigation-brand{
			border-bottom: 1px solid darken($medium-blue, 10);
			#vx-navigation-logo{
				padding: 1em;
			}
		}
		#vx-navigation{
			display: block;
			background: transparent;
			&.show{
				//display: block;
			}
		}
	}
	@media( max-width: 572px ) {
		.hero{
			padding-top: 6em;
		}
	}
}
