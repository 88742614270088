$image_url_base: "https://cdn.veloxwheels.com";
$fonts_url_base: "https://cdn.veloxwheels.com/fonts";

// Colors
$dark-blue: #01315a;
$medium-blue: #004daf;
$medium-blue-light: #207CDA;
$blue: lighten($medium-blue, 15);
$light-blue:  lighten($medium-blue, 40);
$dark-gray: #1c2b3c;
$medium-gray: lighten($dark-gray, 50%);
$light-gray: #EEEEEE;
$cool-grey: #3d4652;
$cool-gray: $cool-grey;

$red: #F96969;
$green: #6DD67A;
$yellow: #FFF68A;
$orange: #F9D547;

$submitted: darken($yellow,30);
$poissued: $blue;
$pending: lighten($green,10);
$cancelled: $red;
$closed: $green;
$shipped: $green;

$brand: $dark-blue;
$light-gradient: linear-gradient(#eeeeee 0%, #fff 1.5%, #fefefe 3%, #fdfdfd 50%,#efefef 99%, #fefefe 100%);
$subtle-light-gradient: linear-gradient(#F9f9f9 0%, #fafafa 3%, #f8f8f8 85%, #f2f2f2 100%);
$blue-gradient: linear-gradient(to bottom, $medium-blue-light, $blue);
$blue-gradient2: linear-gradient(to bottom, #4d9cff, #0072FF);
$dark-gradient: linear-gradient(lighten($dark-gray,10) 1%, lighten($dark-gray,8) 2%, lighten($dark-gray,5) 50%,lighten($dark-gray,3) 100%);

// Typography
$base-font-weight: 400;
$base-font-letter-spacing: 0.01em;
$base-font-size: 18px;
$base-line-height: 1.6666;
$base-font-color: $dark-gray;

$base-font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
$fancy-font-family: 'alternate-gothic-no-3-d', 'Proxima Nova', Helvetica, Arial, sans-serif;
$heading-font-family: $base-font-family;
$serif-font-family: 'adelle', serif;

$heading-font-weight: 700;

// Font Sizes
$font-small: 0.95;
$font-large: 1.25;
$font-base: 1;
$h1: 2;
$h2: 1.5;
$h3: 1.25;
$h4: 1.2;
$h5: 1;
$h6: 1;

// Line height
$heading-line-height: 1.2;

// Padding
$pad-y: $base-font-size*1.25;
$pad-x: $base-font-size;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing * 0.5;
$xs-spacing: $base-spacing * 0.25;
$base-z-index: 0;

// Font Colors
$action-color: $blue;

// Border
$base-border-color: lighten($medium-gray, 25);
$base-border: 1px solid $base-border-color;
$table-border: 2px solid #ddd;
$order-detail-border: 1px solid #aaa;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($blue, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

// Screens
$small-screen: em(400) !default;
$medium-screen: em(640) !default;
$large-screen: em(968) !default;
$sidebar-layout-breakpoint: 1000px;
$horizontal-product-layout-breakpoint: 500px;
$vertical-results-layout-breakpoint: 1400px;

$details-2-col-breakpoint: 700px;
$details-3-col-breakpoint: 1200px;

@import "./mixins";
