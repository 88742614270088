@use "sass:math";

@mixin sixteen-by-nine($given, $value){
	$value: strip-unit($value);

	@if $given == 'height' {
		height: $value * 1px;
		width: (math.div(16, 9)) * $value * 1px;
	}
	@if $given == 'width' {
		width: $value * 1px;
		height: (math.div(9, 16)) * $value * 1px;
	}
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value*16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}

@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}
/*
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
*/
