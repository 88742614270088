.badge, .tags .tag {
  display: inline-block;
  width: initial;
  height: 2.3em;
  padding: 0.4em 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  background: #006ffc;
  color: #fff;
  font-size: 12.8px;
  font-size: 0.8rem;
  font-family: "alternate-gothic-no-3-d", "Proxima Nova", Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.33; }
  .badge.lg, .tags .lg.tag {
    font-size: 1em;
    letter-spacing: 0.2em; }

.tags {
  padding: 0;
  padding-top: 0.5em; }
  .tags .tag {
    display: inline-block;
    width: initial;
    margin-right: 0.5em;
    padding-left: 0.8em;
    padding-right: 1em;
    padding-bottom: 0.4em;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 0.2em rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 0 3px rgba(255, 255, 255, 0.5);
    cursor: pointer;
    border: 1px solid #0058c9;
    border-radius: 0.2em;
    position: relative; }
    .tags .tag > .icon-remove {
      padding-right: 0.25em;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 0.5em; }
    .tags .tag > .icon-cancel {
      fill: rgba(255, 255, 255, 0.5);
      padding: 0.1em;
      margin-right: 0.5em;
      width: 1em;
      vertical-align: middle; }

.wf-alternategothicno3d-n4-active .tag {
  letter-spacing: 0.1em; }

.toolbar {
  background: linear-gradient(#eeeeee 0%, #fff 1.5%, #fefefe 3%, #fdfdfd 50%, #efefef 99%, #fefefe 100%);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: none;
  border-right: none;
  margin: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;
  padding: 0; }
  .toolbar > li {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    width: 100%; }
    .toolbar > li .label {
      padding-right: 1em;
      color: #8daaca;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: bold; }
  .toolbar > li {
    padding: 0.5em; }
  .toolbar > li.sort-options {
    text-align: left;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    align-items: center; }
    @media (min-width: 500px) {
      .toolbar > li.sort-options {
        margin-top: 0.1em; } }
    .toolbar > li.sort-options .icon-sort {
      height: 2em;
      width: 2em;
      vertical-align: middle;
      box-sizing: border-box;
      display: none; }
      .toolbar > li.sort-options .icon-sort path {
        fill: rgba(0, 0, 0, 0.3); }
      @media (min-width: 500px) {
        .toolbar > li.sort-options .icon-sort {
          margin-right: 0.5em;
          padding-bottom: 0.25em;
          display: block; } }
    .toolbar > li.sort-options .dropdown {
      padding: 0; }
      @media (min-width: 500px) {
        .toolbar > li.sort-options .dropdown {
          max-width: 15em; } }
    .toolbar > li.sort-options .rw-widget-input {
      padding: 0.3em; }
    .toolbar > li.sort-options > .label, .toolbar > li.sort-options .dropdown {
      padding-right: 0.5em;
      height: 2.5em;
      width: 100%; }
      @media (min-width: 500px) {
        .toolbar > li.sort-options > .label, .toolbar > li.sort-options .dropdown {
          width: auto;
          flex: 1;
          min-width: 10em; } }
    .toolbar > li.sort-options > .label {
      text-align: right; }
    @media (min-width: 500px) {
      .toolbar > li.sort-options {
        align-items: center;
        text-align: left; }
        .toolbar > li.sort-options > .label {
          display: none; }
        .toolbar > li.sort-options > label, .toolbar > li.sort-options > .dropdown {
          display: inline-block;
          vertical-align: middle; } }
  .toolbar > li.applied-filters {
    margin-top: 0.5em;
    align-items: center;
    display: none;
    letter-spacing: 0;
    transition: height 0.5s ease; }
    .toolbar > li.applied-filters > .tags {
      padding: 0;
      list-style: none; }
    .toolbar > li.applied-filters.no-filters {
      display: none;
      height: 0; }
    .toolbar > li.applied-filters .icon-filter {
      height: 2em;
      width: 2em;
      margin-right: 0.5em;
      vertical-align: middle; }
      .toolbar > li.applied-filters .icon-filter path {
        fill: rgba(0, 0, 0, 0.3); }
    .toolbar > li.applied-filters .filter-field {
      text-transform: uppercase; }
      .toolbar > li.applied-filters .filter-field:after {
        content: " | ";
        font-weight: normal;
        color: rgba(0, 0, 0, 0.2);
        padding-left: 0.1em; }
    .toolbar > li.applied-filters .clear-filters {
      display: inline-block;
      color: #8daaca;
      text-decoration: underline;
      font-size: 0.8em;
      transition: all 0.5s ease;
      cursor: pointer; }
      .toolbar > li.applied-filters .clear-filters:hover {
        color: #006ffc; }
    @media (min-width: 500px) {
      .toolbar > li.applied-filters {
        display: -ms-flex;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0.25em; }
        .toolbar > li.applied-filters > .label {
          display: none; } }

.no-flexbox .toolbar {
  display: block;
  width: 100%; }
  .no-flexbox .toolbar > li.sort-options {
    display: block; }
    .no-flexbox .toolbar > li.sort-options .icon-sort {
      width: 2em;
      height: 2em;
      float: left;
      vertical-align: center;
      padding-top: 0.3em; }
    .no-flexbox .toolbar > li.sort-options .dropdown {
      display: inline-block;
      width: 50%;
      vertical-align: center; }
