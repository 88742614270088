.outlined-note{
	padding: 1em 0.5em;
    color: #999;
    border: 2px solid #eee;
	border-radius: 0.5em;
	&.blue{
		border-color: lighten($medium-blue, 10);
		color: $medium-blue;
	}
	&.green{
		border-color: $green;
		color: darken($green, 10);
	}
	&.yellow{
		border-color: $yellow;
		color: darken($yellow, 30);
	}
	&.red{
		border-color: $red;
		color: darken($red, 10);
	}
}
