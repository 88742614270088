@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/order_history_table.scss';

.dashboard-page{
	.dashboard-hero{
	    background: linear-gradient(0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.9)), linear-gradient(270deg, #0e3b75 0, rgba(14, 59, 117, 0) 60%), url(https://cdn.veloxwheel.com/hero/home-hero-aston1-1600x600.jpg);
	    background-size: cover;
	    background-position: center;
	    .btn-download{
			margin-top: 0.5em;
			height: 1em;
			text-align: center;
			display: flex;
			justify-content: center;
			img{
				width: 1.5em;
				padding-right: 0.5em;
				height: 1em;
			}
	    }
	    .dashboard-module{
	    	.dashboard-module-title{
	    		font-weight: bold;
	    		text-transform: uppercase;
	    		color: #fff;
	    		font-size: 1.3em;
	    		margin-bottom: 0;
	    	}
	    	.counter{
	    		font-size: 2em;
	    		font-weight: bold;
       			@include rem-fallback('padding-top', 0.5);
       			@include rem-fallback('padding-bottom', 1);
	    	}
	    }
	}
	.dashboard-content{
		min-height: 400px;
		.order-history{
			padding: 0;
			margin-top: 1em;
			margin-bottom: 1em;
			table{
				background: #fff;
			}
			@media(max-width: 950px){
				margin-top: 0;
			}
		}
	}
	#vehicle-selection-form-container{
		background: #fff;
		padding: 2em;
		.title{
			color: black;
			text-transform: uppercase;
		}
	}
	#draft-order-search-container{
		background-color: #161a1d;
		background-position: center;
		background-size: cover;
		color: #fff;
		border-top: 1px solid #eee;
		padding: 1em 2em 2em;
		.title{
			font-size: 1.5em;
			color: inherit;
			text-transform: uppercase;
			padding: 0.5em;
			letter-spacing: 0.05em;
		}

	}
	.promo-banner{
		margin-top: 1em;
		margin-bottom: 0.5em;
		padding: 1em 2em;
		background: #A4161A;
		line-height: 1.3;
		.promo-accent, .promo-date{
			text-transform: uppercase;
			color: #ffbfc0;
			font-size: 0.9em;
			letter-spacing: 0.1em;
		}
		.promo-title{
			text-transform: uppercase;
			font-size: 1.2em;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			font-weight: bold;
		}
	}
}
