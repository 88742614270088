@import "./variables";
$font-path: '~react-widgets/lib/fonts';
$img-path: '~react-widgets/lib/img';

@import '~react-widgets/dist/css/react-widgets.css';
//@import 'react-widgets/scss/styles.scss';

.rw-dropdown-list-input{
	input{
		height: auto;
		padding: 0;
		margin: 0;
	}
}

/*
.rw-i{
	font-family: RwWidgets;
	vertical-align: middle;
	//padding: 0.1em 0.5em 0.3em;
}

.rw-dropdownlist{
	border-radius: 0;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.05),
        inset 0px 0px 2px 1px rgba(255,255,255,0.25);
    background: $light-gradient;
    border: 1px solid #ddd;
}
.rw-widget-input{
	padding: 0.5em 1em;
	vertical-align: middle;
	border: 1px solid #d2d2d2;
}
.rw-popup{
	padding: 0;
}
.rw-popup-transition{
	padding: 0;
}
.rw-popup-container{
	width: 100%;
	left: 0;
	right: 0;
}
.rw-popup{
	padding: 0;
}
ul.rw-list{
	padding: 0;
	& > li.rw-list-option{
		width: 100%;
		border-radius: 0;
		padding: 0.25em 0.5em;
		box-sizing: border-box;
		&:hover{
			background: $light-gray;
			border: 0;
		}
		&.rw-state-selected{
			background: $blue;
			color: #fff;
			border: 0;
		}
		&.rw-state-focus{
			background: $blue;
			color: #fff;
			border: 0;
		}
	}
}
.rw-dropdownlist.rw-state-disabled{
	opacity: 0.5;
}
*/
