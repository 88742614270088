@import "../../styles/variables";
@import "../../styles/mixins";

.velox-vehicle-selection-form{

    @import "../../styles/base.scss";
	@include base_typography();

    .btn.submit{
        @include button($red);
        color: #fff;
        background: #ff2c2c;
        font-size: inherit;
        line-height: 1.666;
        padding: 0.5em 1em;
		width: 100%;
		vertical-align: middle;
		border-radius: 4px;
        &:hover{
            background: darken(#ff2c2c, 10);
        }
        &.disabled{
        	&:hover{
        		background: #ff2c2c;
        	}
        }
	}

	&.l-column, &.l-default{
		& > .dropdown{
			padding-bottom: 1em;
			vertical-align: middle;
		}
	}
	&.l-grid{
		& > .dropdown{
			padding-bottom: 1em;
			vertical-align: middle;
			@media( min-width: 520px ){
				display: inline-block;
				width: 50%;
				padding-right: 1em;
				&:nth-child(odd){
					padding-right: 0;
				}
			}
		}
	}
	&.l-modal{
		padding: 2em;
		.title{
			margin-top: 0;
		}
	}
	&.l-row{
		& > .dropdown{
			padding-bottom: 1em;
			vertical-align: middle;
			@media( min-width: 520px ){
				display: inline-block;
				width: 50%;
				padding-right: 1em;
				&:nth-child(odd){
					padding-right: 0;
				}
			}
			@media( min-width: 750px ){
				padding-bottom: 0;
				width: 20%;
				&:nth-child(odd){
					padding-right: 1em;
				}
				&:nth-child(5){
					padding-right: 1em;
				}
			}
		}
		.btn.submit{
			width: 100%;
			@media( min-width: 750px ){
				display: inline-block;
				width: 20%;
			}
		}
		&.no-button{
			& > .dropdown{
				@media( min-width: 750px ){
					width: 25%;
					&:nth-child(odd){
						padding-right: 1em;
					}
					&:nth-child(5){
						padding-right: 0;
					}
				}
			}
			.btn-submit{
				display: none !important;
			}
		}
	}
}

.modal > .velox-vehicle-selection-form{
	.title{
		margin-top: 0;
		@include rem-fallback(font-size, 1.5);
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 0.5em;
	}
	.dropdown{
		margin: 0;
		box-sizing: border-box;
	}
	padding: 0;
}
