@import "../styles/variables";

.loading-placeholder{
		width: 100%;
		height: auto;
		position: relative;
	.wheel-image-placeholder{
		width: 100%;
		height: auto;
	}
	.loading-placeholder-wrapper{
		position: absolute;
		top: 30%;
		width: 100%;
		text-align: center;
		padding: 10%;
		.loading-bar{
			width: 100%;
			height: 1.3em;
			background: $light-gradient;
			border: 1px solid #ddd;
			position: relative;
			border-radius: 0.3em;
		}
		.loading-bar-progress{
			width: 0;
			height: 100%;
			transition: all 0.4s ease-in-out;
			background: $blue-gradient;
			background: repeating-linear-gradient(-45deg, #065ac5, #1e8dff 1px, #006ffc 5px, #08589c 10px);
			position: absolute;
			top: 0;
			left: 0;
			border-radius: inherit;
			overflow: hidden;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
		}
		.loading-bar-progress-text{
			font-size: 0.8em;
			line-height: 1.3em;
			vertical-align: middle;
			color: #fff;
			padding: 0 0.5em;
		}
	}
}
.image-360-viewer{
	width: 100%;
	height: auto;
    background-color: white;
	//border: 1px solid #ddd;
    user-select: none;
	overflow: hidden;
	position: relative;
	cursor: ew-resize;
	.image-360-viewer-images{
		display: flex;
		justify-content: center;
	  	align-items: center;

		img{
			width: 100%;
			height: auto;
			&.is-visible{
				//display: block;
				//opacity: 1;
			}
			&.is-not-visible{
				//display: none;
				//opacity: 0;
			}
		}
	}
	&.is-loaded{
		.loading-placeholder{
			display: none;
		}
		.image-360-viewer-images{
			display: block;
		}
	}
	&.is-not-loaded{
		.wheel-image-placeholder{
			display: block;
		}
		.image-360-viewer-images{
			display: none;
		}
	}
	.image-360-toolbar{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		vertical-align: center;
		flex-direction: row;
		height: 1.5em;
		padding: 0;
		box-sizing: border-box;
		font-size: 1.2em;
		opacity: 1;
		transition: all 0.5s;
		z-index: 2;
		line-height: 1;
		position: absolute;
		bottom: 0;
		&.is-visible{
			opacity: 1;
			display: flex;
		}
		&.is-not-visible{
			opacity: 0;
			display: flex;
		}
		.image-360-toolbar-wrapper{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;
			color: #fff;
			font-family: $fancy-font-family;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			box-sizing: border-box;
			height: 100%;
			position: relative;
		}
		button{
			background: transparent;
			border: 0;
			padding: 0;
			margin: 0;
			&:hover{
				cursor: pointer;
			}
			&:focus{
				outline: 0;
			}
		}
		.image-360-toolbar-item{
			color: inherit;
			padding: 0.5em;
			margin: 0;
			height: 100%;
			//background: linear-gradient( rgba(103,110,117,0.9), rgba(45,49,52,0.9) );
			background: linear-gradient(#738dab 1%, #4a5765 2%, #203144 50%, #213246 100%);
			border: 0;
			transition: all 0.7s;
			&:hover{
				background: linear-gradient(#738dab 1%, #4a5765 2%, #203144 50%, #213246 100%);
			}
			&:first-child{
				border-top-left-radius: 1em;
				border-bottom-left-radius: 1em;
				border-right: 0;
				padding-left: 1em;
				margin-right: -1px;
			}
			&:last-child{
				border-top-right-radius: 1em;
				border-bottom-right-radius: 1em;
				border-left: 0;
				padding-right: 1em;
				margin-left: -1px;
			}
			/*
			&:nth-child(3){
				border-left: 0;
				border-right: 0;
				padding-right: 0.5em;
			}
			*/
			/*
			&:hover{
				background: linear-gradient($blue, $medium-blue);
				//background: linear-gradient( rgba(103,110,117,1), rgba(45,49,52,1) );
				transition: all 0.7s;
			}*/
		}
		.drag-to-spin{
			padding: 0.2em;
			border-left: 0;
			border-right: 0;
			cursor: default;
			/*
			&:hover{
				background: $dark-gradient;
				//background: linear-gradient( rgba(103,110,117,0.9), rgba(45,49,52,0.9) );
			}
			*/
			//border-left: 2px solid rgba(0,0,0,0.1);
			//border-right: 2px solid rgba(0,0,0,0.1);
		}
		.rotate-step-button{
			padding: 0 0.2em 0 0.5em;
			img{ width: 2em; }
		}
		.play-button{
			background: linear-gradient(rgba(56, 61, 66, 0.9), rgba(18, 21, 23, 0.9));
			svg{
				width: 1em;
				padding: 0.2em;
			}
		}
	}
	&:active{
		cursor: grabbing;
	}
	&.is-not-visible{
		display: none;
		animation-name: fadeOut;
		animation-duration: 0.5s;
	}
	&.is-visible{
		animation-name: fadeIn;
		animation-duration: 0.5s;
	}
	/*
	img{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: inherit;
		height: inherit;
	}
	*/
}
