@import '../../styles/variables';

$product-alt-images-width-1: 2em;
$product-alt-images-width-2: 3em;
$product-alt-images-width-3: 4em;
$product-alt-images-width-4: 5em;

.product-details{
    background: #fff;
    text-align: left;
}

.product-details-label{
    @include rem-fallback(font-size, 0.9);
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    color: #777;
    letter-spacing: 0.15em;
}

.product-details-header{
    position: relative;
    text-align: right;
    .close-details{
        display: -ms-flex;
        display: flex;
        text-align: center;
        cursor: pointer;
        color: #777;
        width: 100%;
        height: 100%;
        z-index: inherit;
        background: #F9F9F9;
        background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 67%, #F9F9F9 100%);
        border-bottom: 1px solid #eee;
        padding: 0.8em 1em;
        transition: all 0.5s linear;
        .icon-cancel{
            height: 1em;
            width: 1em;
            margin-right: 1em;
            .icon-cancel-svg{
                fill: #999;
                transition: fill 0.5s linear;
            }
        }
        .vxviz-label{
            //height: 2em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: inherit;
            line-height: 1.3em;
        }
        &:hover{
            //color: #fff;
            background: #eee;
            background: linear-gradient(-180deg, #eee 0%, #efefef 1%, #fafafa 20%, #fafafa 75%, #efefef 99%, #eee 100%);
            .icon-cancel-svg{
                fill: currentColor;
            }
        }
    }
}
.product-details-main, .product-details-info{
    position: relative;
    padding: 2em;
    @media(min-width: $details-2-col-breakpoint){
        display: flex;
        flex-wrap: wrap;
        padding: 2em 4em 2em 2em;
    }
}

.product-details-main{
    & > .product-details-image{
        max-width: 45em;
		margin: 0 auto;
        width: 100%;
        z-index: 6;
        @media(min-width: $details-2-col-breakpoint){
            width: 50%;
        }
        @media(min-width: $details-3-col-breakpoint){
            width: 60%;
            padding: 2em;
        }
        .disclaimer{
        	font-size: 0.8em;
        	line-height: 1.3;
        	color: #aaa;
        	display: block;
			padding: 1em 0;
			text-align: center;
        }
    }
    & > .product-details-main-content{
        position: relative;
        @media(min-width: $details-2-col-breakpoint){
            width: 50%;
            padding-bottom: 2em;
        }
        @media(min-width: $details-3-col-breakpoint){
            width: 40%;
        }
        & > header{
            border-bottom: 2px solid #eee;
            position: relative;
            padding-bottom: 1em;
            margin-bottom: 1em;
            @media(min-width: $details-2-col-breakpoint){
            }
            @media(min-width: $details-2-col-breakpoint){
                &:after{
                   // width: 130%;
                }
            }
        }
    }
    & > .product-details-main-actions{
        width: 100%;
        margin: 0;
        border-top: 1px solid $light-gray;
        padding: 1em 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media(min-width: $details-2-col-breakpoint){
            width: 60%;
            margin-left: 40%;
        }
        @media(min-width: $details-3-col-breakpoint){
            display: block;
            padding: 2em;
            width: 30%;
            margin-left: 0;
            border-top: 0;
        }
    }
}

.product-details-main-content-header{
    display: flex;
    .left{
        width: 80%;
    }
    .right{
        width: 20%;
    }
}

// Styles
.product-details{
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .vxviz-label{
        @extend .product-details-label;
        padding-bottom: 0.5em;
    }
    .product-price{
		.wheel-prices{
			display: flex;
		}
       .wheel-price{
		   padding-right: 1em;
       		.label{
       			//width: 3em;
       			text-transform: uppercase;
       			display: block;
				text-align: center;
				color: black;
       		}
	       	.value{
	        	@include rem-fallback(font-size, 1.5);
	        	font-weight: bold;
	        	padding-left: 0.5em;
	        	display: flex;
				align-items: center;
	       }
	       .original-price{
	       		color: #ccc;
	       		font-weight: normal;
				text-decoration: line-through;
	       }
	       .savings-calculated{
	       		color: $green;
	       		font-weight: normal;
	       }
		   &.store-cost{
			   .label{
					color: $medium-blue;
			   }
		   }
		   &:last-child{
			   padding-right: 0;
		   }
       }
        small{
            font-weight: normal;
            @include rem-fallback(font-size, 0.9);
            text-transform: uppercase;
            padding-left: 0.5em;
            letter-spacing: 0.1em;
        }
        .wrapper{
            display: flex;
        }
    }
    .product-availability{
        .checkmark-circle{
            width: 1em;
            height: 1em;
            margin-right: 0.5em;
            top: 5em;
        }
        .is-available{
            color: $green;
        }
    }
    .product-shipping-details{
        display: flex;
        .icon-shipping{
            width: 1.5em;
            height: 1.5em;
            margin-right: 1em;
            path{
                fill: #B7B7B7;
            }
        }
        .text{
            padding-top: 0.3em;
            @include rem-fallback(font-size, 0.9);
        }
    }
    .wheel-installation-hardware-info{
        display: flex;
        .icon-hardware-parts{
            width: 1.5em;
            height: 1.5em;
            margin-right: 1em;
            .small-wrench{ fill: #ccc;  }
            .big-screw{   fill: #aaa; }
        }
        .text{
            padding-top: 0.3em;
            @include rem-fallback(font-size, 0.9);
        }
    }
    .product-details-main-actions{
        .select-quantity-wrapper{
            display: flex;
            max-width: 500px;
            @include rem-fallback(height, 2.6);
            .select-qty{
                width: 30%;
                height: 100%;
                box-sizing: border-box;
                .dropdown{
                    height: inherit;
                }
                .rw-dropdown-list{
                    text-align: center;
                    height: inherit;
                }
                .rw-input, .rw-widget-input{
                    height: inherit;
                    box-sizing: border-box;
                }
                .rw-widget-input{
                    @include rem-fallback(padding, 0.3);
                }
                .rw-select{
                    border-left: 1px solid rgba(0,0,0,0.1);
                }
                .rw-i-caret-down{
                    color: #aaa;
                }
            }
            .checkout-button{
                width: 70%;
                height: inherit;
                box-sizing: border-box;
            }
            .btn-purchase{
                padding: 0.6em;
                height: 100%;
                width: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        } // end select-quantity-wrapper
		.select-quantity-warning{
			margin-top: 2em;
			position: relative;
			max-width: 30em;
			.select-quantity-warning-text{
				margin-left: 2em;
				font-size: 0.9em;
				cursor: pointer;
				transition: all 1s;
				transition-timing-function: ease;
				.text-main{
					color: #CCCCCC;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					transition: all 0.5s;
					transition-timing-function: ease;
				}
				.text-details{
					//color: #999999;
					opacity: 0;
					font-size: 0.9em;
					transition: all 1s;
					transition-timing-function: ease;
				}
			}
			.icon{
				width: 1.3em;
				height: 1.3em;
				position: absolute;
				left: 0;
				top: 0.1em;
				transition: all 0.6s;
				transition-timing-function: ease;
			}
			&:hover{
				.icon {
					path, circle{
						fill: $blue;
					}
				}
				.text-main{
					color: $blue;
					//text-decoration: underline;
				}
				.text-details{
					opacity: 1;
				}
			}
		}

    }
}
.product-details-main-content-header{
    padding-top: 3em;
    & > .item-number{
        @include rem-fallback(font-size, 0.9);
        text-transform: uppercase;
    }
    .customer-item-number{
        font-weight: normal;
        @include rem-fallback(font-size, 0.9);
        letter-spacing: 0.1em;
        color: #000;
        .label, .value{
            display: inline-block;
            font-size: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            color: inherit;
        }
        .value{
            padding-left: 0.3em;
        }
    }
    .product-name{
        font-weight: bold;
        @include rem-fallback(font-size, 2);
        margin: 0;
    }
    .wheel-finish{
        font-weight: bold;
        color: #B7B7B7;
        letter-spacing: 0.02em;
    }
    .product-series{
        text-align: right;
        .series-text{
            @include rem-fallback(font-size, 0.8);
            display: block;
            font-family: $fancy-font-family;
            text-transform: uppercase;
            font-weight: bold;
            color: #555;
            line-height: 1.2;
            padding-top: 0.5em;
        }
    }
}
.product-details-main-content-list{
    & > li{
        padding-top: 1.5em;
        //border-bottom: 3px dotted $light-gray;
        &:last-child{
            border-bottom: 0;
        }
    }
}
.product-details-info{
    //background: #FAFAFA;
    padding-top: 0;
    color: #222;
    font-weight: 200;
    letter-spacing: 0.02em;
    box-sizing: border-box;
    .wrapper{
        display: block;
        padding-top: 2em;
        border-top: 2px solid #ccc;
        width: 100%;
        @media(min-width: $details-2-col-breakpoint){
            display: -ms-flex;
            display: flex;
        }
    }
    .title{
        @extend .product-details-label;
        padding-bottom: 0.5em;
    }
    .product-details-info-specs{
        margin-top: 1em;
        padding-top: 1em;
        box-sizing: border-box;
        @media(min-width: $details-2-col-breakpoint){
            width: 40%;
            padding-left: 2em;
            margin-top: 0;
            padding-top: 0;
            .wheel-spec{
                .label{
                    width: 10em;
                }
            }
        }
        .specs{
            list-style: none;
            padding: 0;
            border: 1px solid $light-gray;
            background: #fff;
            .wheel-finish{
                color: inherit;
                font-weight: inherit;
            }
            .wheel-spec{
                padding: 0.5em;
                border-bottom: 1px solid $light-gray;
                &:last-child{
                    border-bottom: 0;
                }
                .label{
                    font-weight: bold;
                    text-transform: uppercase;
                    @include rem-fallback(font-size, 0.9em);
                    color: #aaa;
                    display: inline-block;
                    padding-right: 0.5em;
                }
                .value{
                    display: inline-block;
                    padding-right: 0.5em;
                }
            }
        }
    }
    .product-details-info-description{
        @media(min-width: $details-2-col-breakpoint){
            width: 60%;
            padding-right: 2em;
        }
    }
}
.product-alt-images{
	.product-alt-image-thumbnail{
		margin-bottom: 0.1em;
		border: 2px solid #eee;
		background: #fff;
		//border-radius: 0.25em;
		&:hover{
			box-shadow: 1px 2px 6px rgba(0,0,0,0.3), 0px 0px 2px rgba(0,0,0,0.2);
			cursor: pointer;
		}
		&.is-active{
			//box-shadow: 0px 0px 0px 2px rgb(0, 77, 175);
			border: 2px solid $light-blue;
		}
	}
	.product-alt-image-360-thumbnail{
		position: relative;
		background: $medium-blue;
		img{
			opacity: 0.4;
			z-index: 1;
			height: 100%;
			width: 100%;
		}
		.thumbnail-overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			//background: rgba(0,0,0,0.5);
			color: #fff;
			z-index: 100;
			text-align: center;
			vertical-align: middle;
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				opacity: 1;
			}
		}
		.thumbnail-overlay-text{
			font-size: 0.9em;
			font-weight: bold;
			line-height: 1;
			text-transform: uppercase;
		}
	}
} // .product-alt-images

.product-image{
	position: relative;
	z-index: 1;
	.wheel-image{
		animation-name: fadeIn;
		.is-not-visible{
			display: none;
			animation-name: fadeOut
		}
	}
	.slider-arrow {
		display: none;
		width: 2em;
		height: 5em;
		box-sizing: border-box;
		background: linear-gradient(#fff, #eee);
		border: 1px solid #aaa;
		box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1) inset;
		border-radius: 0.4em;
		padding: 1em 0.6em;
		position: absolute;
		z-index: 5;
		cursor: pointer;
		transition: opacity 0.5s ease;
		line-height: 1;
		svg{
			width: 100%;
			height: 100%;
		}
	}
	.slider-arrow-prev{
		left: -1px;
		top: 30%;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		@media(max-width: $horizontal-product-layout-breakpoint){
			left: -2.1em;
		}
	}
	.slider-arrow-next{
		right: -1px;
		top: 30%;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		@media(max-width: $horizontal-product-layout-breakpoint){
			right: -2.1em;
		}
	}
}

.product-image-wrapper{
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	position: relative;

	.product-alt-images{
		display: none;
	}
	.product-image{
		margin: 0;
		width: 100%;
	}
	.product-image-toolbar{
		position: absolute;
		top: 1em;
		right: 1em;
		z-index: 2;
		.fullscreen-button{
			background: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			transition: all 0.4s ease-in-out;
			width: 1.8em;
			height: 1.8em;
			svg{
				width: 100%;
				height: 100%;
				transition: all 0.5s ease;
				transition: inherit;
				polygon{
					fill: #AAAAAA;
					transition: inherit;
				}
			}
			&:hover{
				//transform: scale(1.25, 1.25);
				width: 2em;
				height: 2em;
				polygon{
					fill: $blue;
				}
			}
			&:focus{
				outline: 0;
			}
		}
	}

	&.has-alt-images{
		.product-alt-images{
			display: flex;
			padding-right: 6px;
			flex-direction: row;
			margin-top: 0.5em 0;
			height: 60px;
			&:last-child{
				padding-right: 0;
			}
		}
		.product-alt-image-thumbnail{
			//flex: 1;
			width: 3em;
			height: 3em;
		}
		@media(min-width: 600px){
			.product-image{
				margin: 0 0 0 0;
			}
		}
		@media(min-width: 1200px){
			flex-direction: row;
			align-items: flex-start;
			height: auto;
			.product-alt-images{
				display: flex;
				padding-right: 6px;
				flex-direction: column;
				height: auto;
			}
			.product-alt-image-thumbnail{
				width: 60px;
				height: 60px;
			}
			.product-image{
				//margin: 0 0 0 4em;
			}
		}
	}
}


@import './product_image_slider.scss';

html.is_fullscreen{
	background: radial-gradient( #fff 0%, #fff 50%, #aaa 80%, #999 100%);
}

.product-image-wrapper.is-fullscreen{
	background: radial-gradient( #fff 0%, #fff 50%, #aaa 80%, #999 100%);
	padding: 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	&::backdrop{
		background-color: #FFFFFF;
		background: radial-gradient( #fff 0%, #fff 50%, #aaa 80%, #999 100%);
	}
	.product-image{
		height: 100%;
		width: auto;
		transition: all 0.5s ease-in-out;
		&.is-zoomed{
			transform: scale(1.5,1.5);
		}
	}
	.product-image-main, .image-360-viewer{
		height: 100%;
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		vertical-align: middle;
	}
	.wheel-image, .image-360-viewer{
		height: 100%;
		width: 100%;
		z-index: 1;
		margin: 0 auto;
		background: transparent;
		display: flex;
		.image-wrapper{
			width: 100%;
			height: 100%;
		}
		img, .image-360-viewer-images{
			height: 100%;
			width: 100%;
		}
		&.is-not-visible{
			display: none;
		}
	}
	.product-image-toolbar{
		position: fixed;
		top: 1em;
		right: 1em;
		.fullscreen-button{
			background: linear-gradient(#444,#222);
			padding: 0.5em;
			border: 1px solid rgba(255,255,255,0.5);
			border-radius: 0.4em;
			width: 3em;
			height: 3em;
			polygon{
				fill: #fff;
			}
			&:hover{
				box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
				width: 3.5em;
				height: 3.5em;
				polygon{
					fill: #fff;
				}
			}
		}
	}
	.image-360-toolbar{
		display: none;
	}
	.product-alt-images{
		position: fixed;
		top: 1em;
		left: 1em;
	}
}

.no-flexbox{
    .product-details-main{
        width: 100%;
        & > .product-image{
            display: inline-block;
            z-index: 10;
            .wheel-image{
                height: auto;
                width: 100%;
                max-height: none;
                min-width: none;
                min-height: none;
                img{
                    margin: 0;
                    width: inherit;
                    height: inherit;
                }
            }
        }
        & > .product-details-main-content{
            display: inline-block;
        }
    }
    .product-series{
        .wheel-series-logo{
            height: auto;
            width: 100%;
            max-height: none;
            min-width: none;
            min-height: none;
            img{
                margin: 0;
                width: inherit;
                height: inherit;
            }
        }
    }
    .product-details-info{
        .wrapper{
            @media(min-width: $details-2-col-breakpoint){
            }
        }
    }
	.product-image-wrapper{
		display: block;
		position: relative;
		text-align: center;
		.product-alt-images, ul.product-alt-images{
			height: 3em;
			width: auto;
			display: none;
			margin: 0 auto !important;
			bottom: 0;
			position: absolute;
			list-style: none;
			z-index: 2;
		}
		.product-image{
			margin: 0;
		}
		&.has-alt-images{
			.product-alt-images{
				display: block;
				li{
					display: inline-block;
				}
			}
			@media(min-width: 600px){
				.product-image{
					margin: 0 0 0 0;
				}
			}
			@media(min-width: 1200px){
				flex-direction: row;
				align-items: flex-start;
				.product-alt-images{
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					height: 3.5em;
					float: none;
					li{
						display: block;
						width: 3.5em;
						height: 3.5em;
					}
				}
				.product-image{
					margin-left: 3.5em;
				}
			}
		}
	}
}
.product-image-video{
	width: 0;
	height: 0;
	opacity: 0;
	@media(max-width: 1200px){
		padding-right: 1em;
	}
	@media(max-width: 700px){
		padding-right: 0;
	}
	&.is-visible{
		width: auto;
		height: auto;
		opacity: 1;
	}
	iframe{
		width: 100%;
		height: 400px;
	}

}
