@import './variables';

@keyframes next_step{
	0%{ background: $yellow }
	50%{ background: lighten($yellow, 15); }
	100%{ background: $yellow; }
}
.order-timeline{
	&.hero{
		background: darken($medium-blue, 5);
		color: #fff;
		padding: 1em;
	}
	ol.timeline{
		list-style: none;
		padding: 0.5em 0;
		margin: 0;
		display: flex;
		margin-left: -1em;
		margin-right: -1em;
		li{
			flex: 1;
			align-items: center;
			vertical-align: middle;
			height: auto;
			padding: 0.3em;
			font-weight: bold;
			//text-transform: uppercase;
			position: relative;
			text-align: center;
			//letter-spacing: 0.1em;
			border: 1px solid rgba(0,0,0,0.1);
			display: flex;
			&:first-child{
				border-top-left-radius: 1em;
				border-bottom-left-radius: 1em;
			}
			&:last-child{
				border-top-right-radius: 1em;
				border-bottom-right-radius: 1em;
			}
			&.past{
				background: darken($medium-blue, 5);
				color: $blue;
			}
			&.current{
				background: linear-gradient( #deffbf, #30e668 );
				color: darken( #30e668, 50);
			}
			&.future{
				background: linear-gradient( rgba(0,0,0,0.1), rgba(0,0,0,0.2) );
				color: rgba(255,255,255,0.5);
				//background: linear-gradient( #fafafa, #fff );
				//color: #aaa;
			}
			&.next{
				background: linear-gradient( lighten($yellow, 20), $yellow );
				color: darken( $yellow, 50);
				animation-name: next_step;
				animation-duration: 3s;
				animation-iteration-count: infinite;
				animation-timing-function: ease;
			}
			.timeline-list-item-content{
				flex: 1;
				text-align: center;
				padding: 0.25em 0.5em;
			}
			.product-sku{
				text-transform: uppercase;
				font-size: 0.8em;
				font-weight: normal;
				line-height: 1;
			}
		}
	}
}
