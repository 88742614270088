@use "sass:math";
@import '../../styles/variables';

$visualizer-sidebar-elem-padding: $pad-y*0.25 $pad-x;
$visualizer-sidebar-background-color: $medium-blue;
$visualizer-sidebar-font-color: #fff;
$visualizer-sidebar-accordion-item-font-color: $light-blue;

.velox-visualizer-sidebar{

	@import "../../styles/accordion.scss";

	width: 100%;
	position: relative;
	padding: 1.5em 1.5em 0;
	& > .row{
		display: -ms-flex;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
	}
	.link-change-vehicle{
		@include rem-fallback(font-size, 0.8);
		font-weight: bold;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $light-blue;
		&:hover{
			color: #fff;
			text-decoration: underline;
		}
	}
	.vehicle-color-options{
		.dropdown, .rw-dropdownlist, .rw-dropdownlist-picker, .rw-input{
			border-radius: 0;
			height: inherit;
		}
		.vehicle-color-options-button{
			width: 100%;
		}
	}
	.btn-change-vehicle{
		display: flex;
		letter-spacing: 0.1em !important;
		font-size: 0.9em !important;
		font-size: 0.9rem !important;
		padding: 0.5em 1em !important;
	}
	.selected-vehicle{
		letter-spacing: 0.1em;
		padding-bottom: 1em;
		& > .vxviz-sidebar-label{
			text-transform: uppercase;
		    color: rgba(255, 255, 255, 0.5);
		    font-weight: bold;
			@include rem-fallback(font-size, 0.9);
		}
		& > .vehicle-name{
			margin-top: 0;
			margin-bottom: 0;
			letter-spacing: 0.1em;
			@include rem-fallback(font-size, 1.3);
		}
	}
	.selected-size{
		font-size: 0.8em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		width: 50%;
		.dropdown{
			text-align: center;
		}
	}
	.accordion, .accordion-item{
		background: transparent;
	}
	.accordion-item{
		border: 0;
	}
	@media( min-width: $sidebar-layout-breakpoint ){
		display: block;
		background: none;
		max-width: 15em;
		position: relative;
		.vehicle-color-options{
			display: none;
		}
		.selected-vehicle{
			padding-bottom: 0.6em;
		}
		.selected-size{
			padding: 1.2em 0;
			border-top: 1px solid rgba(0,0,0,0.6);
			border-bottom: 1px solid rgba(0,0,0,0.6);
			width: 100%;
			.dropdown{
				max-width: 12em;
			}
		}
		.mobile-filter-button{
			display: none !important;
		}
	}
	.icon-edit-pencil{
		width: 1em;
		height: 1em;
		path{
			fill: #fff;
		}
	}
}

.velox-visualizer-sidebar.blue{
	background: $medium-blue;
	color: #fff;
	.result-filters{
		background: $medium-blue;
		color: #fff;
	}
	.accordion-menu > .accordion-item{
		//color: $light-blue;
		transition: color 0.5s ease;
		&:hover{
			color: #fff;
		}
		input:checked+label {
		    color: #fff !important;
	    }
	}
}
.velox-visualizer-sidebar.gray{
	background: linear-gradient(#fff, #fafafa);
	color: #555;
	border-right: 2px solid #ccc;
	.accordion-menu > .accordion-item{
		//color: $light-blue;
		transition: color 0.5s ease;
		&:hover{
			color: $blue;
		}
		input:checked+label {
		    color: #111 !important;
	    }
	}
}

.velox-visualizer-sidebar{
	background: $medium-blue;
	color: #fff;
	.accordion-menu > .accordion-item{
		//color: $light-blue;
		transition: color 0.5s ease;
		&:hover{
			color: #fff;
		}
		input:checked+label {
		    color: #fff !important;
	    }
	}
}

.velox-visualizer-sidebar.is-mobile{
  overflow: visible;
  position: initial;
	& > .row{
		background: linear-gradient(rgba(0,0,0,0.07), rgba(0,0,0,0.05), rgba(0,0,0,0.1));
		border: 1px solid rgba(0,0,0,0.1);
		margin: 0 -1.5em 0em;
		padding: 1em;
	}
	.vehicle-color-options{
		display: block;
		@include rem-fallback(height, 3);
		width: percentage( math.div(3, 12) );
		text-align: center;
		.icon-color-palette{
			float: none;
		}
		.vxviz-sidebar-label{
			display: none;
			text-align: left;
		}

		@media( min-width: 448px ){
			width: percentage(math.div(4, 12));
			.icon-color-palette{
				float: left;
				margin-right: 0.5em;
			}
			.vxviz-sidebar-label{
				display: block;
			}
		}
		border-radius: 0;
		.dropdown{
			width: 100%;
		}
		.dropdown:hover{
			width: 100%;
		}
		.rw-list{
			min-width: initial;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			li{
				padding: 0;
				width: percentage( math.div(1, 1) );
			}
		}
	}
	.vehicle-color-options-button.btn{
		@media( max-width: 448px ){
			padding: 0.6em;
			text-align: center;
			img{
				margin: 0 auto;
			}
			.label{
				display: none;
			}
		}
		@media( max-width: 800px ){
			padding-top: 0.8em;
		}
		@media( min-width: 448px ){
			img{
				margin: 0;
				margin-right: 0.4em;
			}
		}
	}
	.btn-change-vehicle{
		width: percentage(math.div(6, 12));
		@include rem-fallback(height, 3);
		line-height: 1;
		border-radius: 0;
		border: 0;
		box-sizing: border-box;
		padding: 0;
		display: block;
		.icon-edit-pencil{
			display: none;
		}
	}
	.selected-size{
		width: percentage(math.div(7, 12));
		position: relative;
		@include rem-fallback(height, 3);
		font-size: 1em;
		line-height: 1;
		box-sizing: border-box;
		box-shadow: 0;
		padding: 0;
		border: 0;
		.vxviz-sidebar-label{
			position: absolute;
			padding: 0.1em;
			top: 0.5em;
			left: 18px;
			color: #999;
			z-index: 3;
			text-transform: uppercase;
        	@include rem-fallback(font-size, 0.7);
		}
		.dropdown{
			padding-left: 0.5em;
			.rw-widget-input{
				padding-left: 0.2em;
				padding-right: 0.2em;
			}
		}
		.rw-input{
			padding-top: 0.6em;
		}
		.rw-select{
			width: auto;
		}
		.dropdown, .rw-dropdownlist{
			height: inherit;
		}
		@media( min-width: 448px ){
			width: percentage(math.div(6, 12));
		}
	}
	.mobile-filter-button{
		width: percentage(math.div(2, 12));
		@include rem-fallback(height, 3);
		padding-top: 0;
		padding-left: 0.5em;
		display: flex;
		.btn{
			height: inherit;
			width: 100%;
			background: linear-gradient(rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.1) 1%, rgba(255,255,255,0.1) 10%, rgba(0,0,0,0.05) 98%, rgba(0,0,0,0.2) 100%);
			box-shadow: 0 0 0.1em rgba(255, 255, 255, 0.1) inset, 0 0 2px 1px rgba(0, 0, 0, 0.1);
			border: 1px solid rgba(0,0,0,0.2);
		}
		.icon-filter{
			width: 100%;
			max-width: 1.5em;
			padding-top: 0.2em;
			path{
				fill: rgba(255,255,255,0.7);
			}
		}
	}

	&.is-menu-collapsed{
		.result-filters{
		  //transform: scaleY(0);
		  //transform: translate(0, -100%);
		  height: 0;
		  opacity: 0;
		  display: none;
		  .title{ margin-top: 0; }
		}
	}
	&.is-menu-open{
		.mobile-filter-button{
			.btn{
				background: rgba(0,0,0,0.4);
				box-shadow: 0 0 1em rgba(255, 255, 255, 0.3);
			}
		}
		.result-filters{
			background: rgba(0,0,0,0.4);
			margin: 0 -1.5em;
			padding-left: 1em;
			padding-right: 1em;
		  	transform: translate(0, 0);
		  	display: block;
		}
	}
}
