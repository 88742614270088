@import '../../../styles/variables';

$vehicle-color-options-dropdown-radius: 2em;

.vehicle-color-options{
	z-index: 6;
    position: relative;
    max-width: 100%;
    width: 100%;
    *{
    	box-sizing: border-box;
    }
    @media( min-width: $sidebar-layout-breakpoint ){
        display: block;
    	max-width: 13em;
    }
	.vehicle-color-options-button.btn{
	    color: #000;
	    cursor: pointer;
	    font-family: inherit;
	    font-size: inherit;
	    font-weight: 600;
	    line-height: 1.3;
	    padding: 0.2em 0.5em;
	    text-decoration: none;
	    text-align: left;
	    transition: background-color $base-duration $base-timing;
	    user-select: none;
	    vertical-align: middle;
	    white-space: nowrap;
	    position: relative;

	    @include button(#eee);
		width: inherit;
		height: inherit;
		padding: 0.5em 1em;
		display: block;
		align-content: middle;
		z-index: 7;
		font-weight: normal;
		//font-family: $fancy-font-family;
		.icon-color-palette{
			line-height: 1.5;
			width: 1em;
			height: 1em;
			float: left;
			margin-right: 0.5em;
			display: inline-block;
		}
		.vxviz-label{
			line-height: 1.5;
	   	 	text-align: left;
	   	 	display: inline-block;
		}
	}
	.vehicle-color-options-list{
		z-index: 6;
		position: absolute;
		width: 100%;
		padding: 0.25em;
		border: 1px solid #aaa;
		background: #fff;
		box-shadow: 1px 1px 12px 2px rgba(0,0,0,0.2);
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		list-style: none;
		&.is-closed{
			display: none;
		}
	    @media( max-width: 800px ){
	    	width: 400px;
	    }
	    @media( max-width: 450px ){
	    	width: 300px;
	    }
	    @media( max-width: 350px ){
	    	width: 250px;
	    }
	    @media( max-width: 300px ){
	    	width: 200px;
	    }
		.vehicle-color-options-list-item{
			display: block;
			width: 50%;
			cursor: pointer;
			padding: 0.25em;
			border: 1px solid #ddd;
			.color-swatch{
				opacity: 0.9;
			}
			&:hover{
				.color-swatch{
					opacity: 1;
				}
			}
			&.is-selected{
				box-shadow: 0 0 0px 2px #0083ff inset;
			}
		    @media( max-width: 800px ){
		    	width: 25%;
		    }
		    @media( max-width: 450px ){
		    	width: 33%;
		    }
		    @media( max-width: 350px ){
		    	width: 50%;
		    }
		}
		.color-swatch{
			text-align: center;
			padding: 0.6em;
			display: -ms-flex;
			display: flex;
			height: 4em;
			position: relative;
			z-index: 4;
			line-height: 1;
			text-overflow: ellipsis;
			overflow: hidden;
			@include rem-fallback('font-size', 0.8);
			font-family: $fancy-font-family;
			.color-name{
				text-transform: uppercase;
				letter-spacing: 0.1em;
				text-align: center;
				opacity: 0.5;
				display: none;
			}
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.1));
				z-index: 5;
			}
		}
	}
}
