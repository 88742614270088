@import "./variables";
&.is-root-loading {
	background: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.18));
	background-size: cover;
	background-repeat: none;
	background-position: center;
	background-attachment: fixed;
	//background: linear-gradient(darken($light-gray, 5), darken($light-gray, 10));
	//background: rgba(0,0,0,0.2);
	//background: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.1)), url("https://cdn.veloxwheels.com/bground/brushedalum.png");
	height: 100%;
	height: 100vh;
	display: flex;
	vertical-align: middle;
	justify-content: middle;
	align-items: middle;

	&.is-root-loading-blue{
		background: $blue;
		background: linear-gradient($blue, $medium-blue);
	}

	#root {
		flex: 1;
		height: 100%;
		height: 100vh;
		width: 100%;
		vertical-align: middle;
		align-items: center;
		justify-content: center;
		text-align: center;
		display: block;
		padding-top: 0;
		position: relative;
		.velox-vehicle-visualizer {
			.loading {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin: auto;
				background: transparent;
				width: 200px;
				text-align: center;
			}
		}
		.icon-loading.loader-small {
			max-width: 120px;
			min-width: 120px;
		}
		.loading-text {
			color: #fff;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			padding-top: 1em;
			display: block;
			position: relative;
			right: -0.5em;
		}
	}
}
