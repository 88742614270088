@import './variables';

$order-history-table-border: 1px solid #eee;
.order-history{
	header{
		//background: linear-gradient(#fff, #fafafa);
		//border: $order-history-table-border;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1em;
		.title{
			font-weight: bold;
			width: 25%;
		}
		.filters{
			display: flex;
		}
		#order-search{
			width: 25em;
		}
		.filter-status{
			width: 10em;
			margin-left: 0.5em;
			@media( max-width: map-get($grid-breakpoints, "md")) {
				margin-left: 0;
			}
		}
		.filter-date{
			width: 20em;
			display: flex;
		}
		.form-control, .input-group, select, select.form-control:not([size]):not([multiple]){
			height: 3em;
			border-radius: 0;
		}
		@media( max-width: map-get($grid-breakpoints, "md")) {
			display: block;
			.title{
				width: 100%;
			}
			#order-search{
				width: 100%;
			}
		}
		@media( max-width: map-get($grid-breakpoints, "md")) {
			.filters{
				display: block;
				.filter-status{
					width: 100%;
				}
			}
		}
	}
	.order-history-table{
		border: $order-history-table-border;
		font-size: 0.8em;
		tbody tr{
			&:hover{
				background: #fafafa;
			}
		}
		th, td{
			padding: 1.5em 0.75em;
		}
		th{
			border-top: 0;
			padding-top: 1em;
			padding-bottom: 0.5em;
		}
		td{
			.icon{
				width: 1.2em;
				height: 1.2em;
				path{
					fill: #ccc;
				}
			}
			.icon-phone, .icon-vehicle{
				float: left;
				margin-right: 0.5em;
			}
		}
		.customer-name{
			font-weight: bold;
		}
		.order-items{
			padding: 0;
			margin: 0;
			list-style: none;
			.description{
				padding-bottom: 0.3em;
			}
			.sku, .item-num, .qty{
				font-size: 0.9em;
			}
			label{
				color: #aaa;
			}
		}
		.row-no-results{
			&:hover{
				background: inherit;
			}
		}
		.row-status-cancelled{
			opacity: 0.5;
		}
		.row-status-pending{
			td:first-child{
				position: relative;
				&:after{
					content: "";
					height: 100%;
					width: 4px;
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					background: $green;
				}
			}
		}
		.cell-date{
			.time{
				color: #aaa;
			}
		}
		.cell-no-results{
			text-align: center;
			padding: 5em 2em;
			height: 10em;
			font-size: 1rem;
			.title{
				font-weight: bold;
			}
			.wrapper{
				display: flex;
				align-items: center;
				width: 100%;
				height: inherit;
				.content{
					width: inherit;
					height: inherit;
				}
			}
			a{
				color: $blue;
				cursor: pointer;
			}
		}
		.cell-mobile-only{
			display: none;
			position: relative;
			padding: 1em;
			.details{
				padding: 0;
				margin: 0;
				list-style: none;
				li{
					display: flex;
					padding: 0.25em 0;
					label{
						width: 2em;
						margin-right: 1em;
						color: #aaa;
						text-align: left;
						.icon{
							width: 1.2em;
							height: 1.2em;
						}
						svg path{
							fill: currentColor;
						}
					}
				}
				.order-item{
					.sku{
						color: #aaa;
						font-size: 0.8em;
					}
				}
			}
			.order-meta{
				position: absolute;
				top: 1em;
				right: 1em;
				text-align: right;
				.warehouse{
					background: #eee;
					color: #999;
					padding: 0.25em 0.5em;
					border-radius: 0.1em;
					margin-top: 0.5em;
					text-align: center;
					font-size: 0.8em;
					display: inline-block;
				}
			}
		}
		@media( max-width: map-get($grid-breakpoints, "sm")) {
			td, th{
				display: none;
				&.cell-mobile-only, &.cell-no-results{
					display: table-cell;
				}
			}
			thead{
				display: none;
			}
		}
	}
	.pagination-footer{
		display: flex;
		justify-content: space-between;
		.pagination-form{
			.input-group{
				input, button{
					padding: 0.5rem 0.75rem;
				}
				input{
					width: 5em;
				}
				button{
					background: #fafafa;
					border-color: #aaa;
				}
			}
		}
	}
}
