@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/fonts.scss";

.velox-vehicle-visualizer {
    color: $base-font-color;
    font-family: $base-font-family;
    font-feature-settings: "kern", "liga", "pnum";
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height;
    letter-spacing: $base-font-letter-spacing;
    min-height: 800px;
    position: relative;
    background: #fafafa url("https://cdn.veloxwheels.com/bground/brushedalum.png") repeat;
    *{
        box-sizing: border-box;
    }
    img {
      width: 100%;
      max-height: 100%;
      display: block;
    }
    @import "../styles/base.scss";
    @media( min-width: $sidebar-layout-breakpoint) {
        display: -ms-flexbox;
        display: flex;
    }
    .hide-mobile {
        display: none;
        @media( min-width: $sidebar-layout-breakpoint) {
            display: initial;
        }
    }
    .show-mobile {
        display: block;
        @media( min-width: $sidebar-layout-breakpoint) {
            display: none;
        }
    }
}

.no-flexbox{
  html, body, #root, #root>div, .velox-vehicle-visualizer{
    height: 100%;
  }
  .velox-vehicle-visualizer {
    position: relative;
    padding: 0;
    @media( min-width: $sidebar-layout-breakpoint) {
        display: block;
        .velox-visualizer-sidebar{
          width: 20%;
          max-width: none;
          height: 100%;
          box-sizing: border-box;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        }
        .velox-visualizer-main{
          width: 80%;
          margin-left: 20%;
          box-sizing: border-box;
          position: relative;
          top: 0;
          display: inline-block;
        }
    }
    @media( min-width: 1200px) {
      padding-left: 15em;
      .velox-visualizer-sidebar{
        width: 15em;
      }
      .velox-visualizer-main{
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

body.has-velox-vehicle-visualizer{
    //background: #fafafa url("https://cdn.veloxwheels.com/bground/brushedalum.png") repeat;
    @import "../styles/loading_root.scss";
}

body.wf-alternategothicno3d-n4-active{
  h1{
    font-size: $h1;
    //font-family: $fancy-font-family;
    //letter-spacing: 0.05em;=
    position: relative;
    &:before{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(rgba(255,255,255,0.3),rgba(0,0,0,0));
    }
    //text-shadow: 0px 3px 1px #999;
  }
  h2{
    font-size: $h2*1.5;
    //text-transform: uppercase;
  }
  h3{ font-size: $h3*1.5; }
  h4{ font-size: $h4*1.5; }
  h5{ font-size: $h5*1.5; }
  h6{ font-size: $h6*1.5; }

  .hero{
    h1{
      margin: 0;
    }
  }
}
body.wf-proximanova-n7-active{
  figure{
    figcaption{
      letter-spacing: 0.1em;
      text-transform: initial;
    }
  }
  h1,h2,h3,h4,h5,h6{
    &.fancy{
      font-family: $fancy-font-family;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
}


@import "../styles/modals.scss";
